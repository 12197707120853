export const inputBlur = {
  data() {
    return {};
  },
  methods: {
    inputLoseFocus() {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
  },
};
