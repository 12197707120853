<template>
  <div>
    <!-- the login form popup -->
    <van-popup
      v-model="showLogin"
      :overlay-style="{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }"
      :close-on-click-overlay="false"
      :overlay="true"
      class="radiusPop"
    >
      <div class="register">
        <div class="tel">
          <div class="telPre">
            <!-- the pre of the phone number -->
            <div @click.stop.prevent="selectPre" v-if="countryCodeList">
              {{ countryCodeList[defaultPre].TelephoneCode }}
            </div>
            <div class="select-icon" @click.stop.prevent="selectPre"></div>
            <div class="">
              <input
                type="tel"
                name="telphone"
                id="telphone"
                v-model.trim="telphone"
                placeholder="输入手机号"
                @blur.prevent="inputLoseFocus"
              />
            </div>
          </div>
        </div>
        <div class="tel">
          <div class="code">
            <input
              type="text"
              v-model="code"
              placeholder="请输入验证码"
              @blur.prevent="inputLoseFocus"
            />
            <!-- the btn get code -->
            <!-- <PhoneCode :codeKey="codeKey"
                       :codeBtn="codeBtn"
                       :codeText="codeText" @getCode="getCode"></PhoneCode> -->
            <div
              :class="[codeBtn, { 'btn-disabled': codeClick }]"
              @click.prevent="getCode"
            >
              {{ codeText }}
            </div>
          </div>
        </div>
        <div class="protocol-tips">
          点击登录即表示同意
          <span @click="$router.push(`/protocol/100201`)" class="green"
            >《用户协议》</span
          >
        </div>
        <!-- login btn -->
        <div
          :class="['loginBtn', { 'login-disabled': disabledSubmit }]"
          @click.prevent="loginHandler"
        >
          登录
        </div>
        <!-- dropdown select list -->
        <transition name="slide">
          <div class="pre-select" v-show="showSelect">
            <ul>
              <li
                v-for="(c, index) in countryCodeList"
                :key="c.ID"
                :class="{ active: defaultPre == index }"
                @click="change(index)"
              >
                {{ c.TelephoneCode }}
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Popup, Toast } from "vant";
// import PhoneCode from '../phoneCode/phoneCode'
import { getVerificationCode, login } from "@/service/";
import { mapState, mapActions, mapMutations } from "vuex";
import { getStore } from "../../lib/storage";
import { validateTel, validateTelCode } from "@/lib/validate";
import { inputBlur } from "@/mixins/inputBlur";

export default {
  name: "login",
  mixins: [inputBlur],
  components: {
    [Popup.name]: Popup,
    [Toast.name]: Toast,
    // PhoneCode
  },
  // props: {
  //   showLogin: {
  //     type: Boolean,
  //     default: false
  //   }
  // },
  data() {
    return {
      //   show: true,
      telphone: "",
      code: "",
      showSelect: false,
      defaultPre: 0,
      // 验证码按钮初始化值
      codeText: "获取验证码",
      // 验证码key值
      codeKey: "1",
      // 验证码按钮 class
      codeBtn: "codeBtn",
      codeClick: false,
      phoneCodeText: this.codeText,
      disabledSubmit: false,
    };
  },
  computed: {
    ...mapState({
      // wxinfo: ({ userVal }) => userVal.wxinfo,
      countryCodeList: ({ globalVal }) => globalVal.countryCodeList,
    }),
    wxinfo() {
      return JSON.parse(getStore("wx"));
    },
    showLogin: {
      get() {
        return this.$attrs.showLogin;
      },
      set() {},
    },
  },
  methods: {
    ...mapMutations(["SET_USER_INFO"]),
    ...mapActions(["getUserInfo"]),
    selectPre() {
      this.showSelect = !this.showSelect;
    },
    change(index) {
      this.defaultPre = index;
      this.showSelect = false;
    },
    async loginHandler() {
      this.disabledSubmit = true;
      let pre = this.countryCodeList[this.defaultPre].TelephoneCode;
      let telMsg = validateTel(pre, this.telphone);
      if (telMsg !== true) {
        Toast(telMsg);
        return;
      }
      let codeMsg = validateTelCode(this.code);
      if (codeMsg !== true) {
        Toast(codeMsg);
        return;
      }
      let params = [
        getStore("state") || "",
        this.telphone,
        this.wxinfo.openid,
        this.wxinfo.headimgurl,
        this.wxinfo.nickname,
        "weixin",
        "weixin",
        "",
        this.countryCodeList[this.defaultPre].TelephoneCode,
        this.code,
        "0",
      ];
      // console.log(params)
      // this.getUserInfo(params)
      // loading start
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 禁用背景点击
        loadingType: "spinner",
        message: "loading...",
      });
      try {
        let res = await login(...params);
        this.disabledSubmit = false;
        // console.log(res)
        if (res && res.code == 1) {
          // loading close
          Toast.clear();
          this.SET_USER_INFO(res.data);
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          this.$emit("islogin");
          this.$router.push("/home");
        } else {
          this.disabledSubmit = false;
          Toast(res.msg);
        }
      } catch (error) {
        this.disabledSubmit = false;
        // loading close
        Toast.clear();
      }
    },
    async getCode() {
      let pre = this.countryCodeList[this.defaultPre].TelephoneCode;
      let telMsg = validateTel(pre, this.telphone);
      if (telMsg !== true) {
        Toast(telMsg);
        return;
      }
      this.codeClick = true;
      let count = 60;
      // let res;
      let countDown = setInterval(() => {
        this.codeText = `${count}秒后获取`;
        count--;
        if (count === 0) {
          clearInterval(countDown);
          this.codeText = "重新获取";
          this.codeClick = false;
        }
      }, 1000);
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 禁用背景点击
        loadingType: "spinner",
        message: "loading...",
      });
      try {
        let res = await getVerificationCode(pre + this.telphone, this.codeKey);

        if (res.code == 1) {
          Toast.clear();
          // console.log(res)
        } else {
          Toast.clear();
          Toast(res.msg);
        }
      } catch (error) {
        // console.log(error)
        Toast.clear();
      }
      // if (res.code === 1) {

      // } else {
      //   // this.$message.error({ message: data.msg })
      // }
    },
  },
  mounted() {
    if (!this.countryCodeList) {
      this.getCountry();
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";

.radiusPop {
  border-radius: 20px !important;
}

.van {
  &-popup {
    position: absolute !important;
  }
  &-overlay {
    position: absolute !important;
  }
}

.register {
  .size(690px, 512px);
  margin: 0 auto;
  padding: 60px;
  .bg(@white);
  border-radius: 20px !important;
  box-sizing: border-box;
  position: relative;
}
.loginBtn {
  .size(570px, 80px);
  margin: 0 auto;
  .bg(@brand-green);
  .fontSize(30px);
  .color(@white);
  .text-center;
  line-height: 80px;
  .border-radius(4px);
}
.tel {
  .fontSize(30px);
  border-bottom: 2px solid #eee;
  input {
    .fontSize(30px);
    line-height: 42px;
  }
}

.telPre {
  display: flex;
  flex: 1;
  align-items: center;
  // .size(72px, 92px);
  line-height: 92px;
}

.select-icon {
  .size(12px, 20px);
  margin-left: 10px;
  margin-right: 20px;
  background: url("../../assets/back@2x.png") no-repeat left center;
  background-size: 12px 20px;
}

.code {
  display: flex;
  flex: 1;
  height: 92px;
  align-items: center;
  justify-content: space-between;
}

.codeBtn {
  .size(160px, 50px);
  .color(@brand-green);
  background: rgba(75, 143, 91, 0.1);
  .text-center;
  line-height: 50px;
  .fontSize(24px);
  .border-radius(4px);
}

.btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.1);
  .color(#999);
}

.protocol-tips {
  margin: 20px 0 60px;
  .fontSize(24px);
  line-height: 34px;
  .color(#999);
}
.pre-select {
  width: 200px;
  height: 128px;
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 30px;
  top: 132px;
  .fontSize(30px);
  .color(#999);
  .text-center;
  overflow-y: auto;
  // -webkit-overflow-scrolling: touch;
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    height: 64px;
    border-bottom: 2px solid #eee;
    line-height: 64px;
  }
}

.slide-enter-active {
  animation: slide-in 0.2s;
}
.slide-leave-active {
  animation: slide-in 0.2s reverse;
}

@keyframes slide-in {
  0% {
    height: 0;
  }
  50% {
    height: 96px;
  }
  100% {
    height: 192px;
  }
}
.active {
  .bg(#eee);
}

.login-disabled {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
