// global
export const CHANGE_READY_STATUS = 'CHANGE_READY_STATUS'
export const GET_COUNTRY_CODE_LIST = 'GET_COUNTRY_CODE_LIST'
export const GET_USER_LOCATION = 'GET_USER_LOCATION'
export const INIT_USER_LOCATION = 'INIT_USER_LOCATION'
export const SET_START_PLACE = 'SET_START_PLACE'
export const SET_END_PLACE = 'SET_END_PLACE'
export const SET_MSG_LIST = 'SET_MSG_LIST'
export const SET_MSGNUMS = 'SET_MSGNUMS'
export const SET_ORDER_ID = 'SET_ORDER_ID'
export const SET_TRANSFER_PLACE = 'SET_TRANSFER_PLACE'
export const GET_CARLIST_AROUND = 'GET_CARLIST_AROUND'
export const SET_PEOPLE_NUM = 'SET_PEOPLE_NUM'
export const SET_ACTIVEITEMID_NUM = 'SET_ACTIVEITEMID_NUM'
export const SET_ERRANDID = 'SET_ERRANDID'
export const SELECT_CAR = 'SELECT_CAR'
export const GET_SEARCH_HISTORY = 'GET_SEARCH_HISTORY'
export const GET_BUSINESS_DISTRICT = 'GET_BUSINESS_DISTRICT'
export const GET_RATE_NUM = 'GET_RATE_NUM'
export const SET_HOMEACTIVEID = 'SET_HOMEACTIVEID'
export const CLEAR_STARTADDRESS = 'CLEAR_STARTADDRESS'
export const CLEAR_POS = "CLEAR_POS"
export const SET_SHOW_ORDER = 'SET_SHOW_ORDER'
// path
export const SET_START_LOCATION = 'SET_START_LOCATION';
export const SET_END_LOCATION = 'SET_END_LOCATION';
export const SET_TRANSFER_LOCATION = 'SET_TRANSFER_LOCATION';
export const ADD_TRANSFER_LOCATION = 'ADD_TRANSFER_LOCATION';
export const DEL_TRANSFER_LOCATION = 'DEL_TRANSFER_LOCATION';
export const DEL_TRANSFER_LOCATION_ALL = 'DEL_TRANSFER_LOCATION_ALL';
// user
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_USER_INFO2 = 'SET_USER_INFO2'
export const GET_USER_ADDRESS = 'GET_USER_ADDRESS'
export const DEL_USER_ADDRESS = 'DEL_USER_ADDRESS'
export const GET_COUPON_LIST = 'GET_COUPON_LIST'
export const SET_USER_ORDERITEM = 'SET_USER_ORDERITEM'
export const CHANGE_QUERY_HISTORY = 'CHANGE_QUERY_HISTORY'