import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import axios from '@/service/http';
import 'lib-flexible/flexible.js';
import 'animate.css';


import Chat from './lib/im/im';
Vue.prototype.$chat = new Chat();

import Mui from 'vue-awesome-mui';
Vue.config.productionTip = false;
Vue.use(Mui);

Vue.prototype.$http = axios;
App.mpType = 'app';
import VueLoadmore from 'vuejs-loadmore';
Vue.use(VueLoadmore);
 
// import Vconsole from 'vconsole';
// const vConsole = new Vconsole();
// Vue.use(vConsole);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');