<template>
  <div>
    <div class="headerbar">
      <div class="headerbar-left-icon">
        <div @click="goBack">
          <img
            src="../../assets/fanhui@2x.png"
            alt
            v-show="showLeft"
          />
          <img
            src="../../assets/user.png"
            alt
            v-show="showUser"
            class="usericon"
          />
        </div>
      </div>
      <div
        class="headerbar-rtitle"
        @click="refreshaa()"
      >
        <span>{{ title }}</span>
        <img
          src="../../assets/refresh.png"
          alt="刷新"
          class="refresh"
          v-if="refresh"
        >
      </div>

      <div
        class="headerbar-right-icon"
        v-if="!showRight"
      >
        <div></div>
      </div>

      <!-- <div
        class="headerbar-right-icon"
        
      >
        
      </div> -->

      <div
        class="header-right-txt"
        v-if="showRight"
      >
        <router-link to="phone">{{righttxt}}</router-link>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "headerbar",
  props: {
    righttxt: {
      type: String,
      default: "浣熊出行",
    },
    title: {
      type: String,
      default: "浣熊出行",
    },
    showLeft: {
      type: Boolean,
      default: true,
    },
    showUser: {
      type: Boolean,
      default: false,
    },
    showRight: {
      type: Boolean,
      default: false,
    },
    refresh: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    refreshaa() {
      if (this.refresh) {
        this.$emit("refreshaa");
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";

.headerbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 22px 32px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  font-size: 36px;
  font-family: "PingFangSC-Medium";
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 50px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  &-left {
    width: 200px;
    background: red;
    &-icon {
      .size(18px, 34px);
      img {
        width: 100%;
      }
    }
  }
  .refresh {
    .size(40px, 40px);
    margin-left: 10px;
    transform: rotateY(180deg);
  }
  &-right {
    &-icon {
      .size(100px, 8px);
      font-size: 20px;
    }
  }
  .header-right-txt {
    font-size: 30px;
    a {
      color: #333333;
    }
  }
  &-title {
    text-align: center;
  }
  &-rtitle {
    margin-left: 15%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .usericon {
    width: 40px;
    height: 40px;
  }
}
</style>
