import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/home/Home.vue';
import {
    getopenId
} from '@/service/';
import {
    setStore
} from "./lib/storage"

 

const Login = () =>
    import( /*webpackChunkName: "login"*/ './views/login/login.vue');
const driver = () =>
    import( /*webpackChunkName: "driver"*/ './views/login/driver.vue');
const OAuth = () =>
    import( /*webpackChunkName: "oauth"*/ './views/oauth/oauth.vue');
const InviteOauth = () =>
    import( /*webpackChunkName: "inviteoauth"*/ './views/oauth/inviteoauth.vue');

const Invitelogin = () =>
    import( /*webpackChunkName: "invitelogin"*/ './views/login/invitelogin.vue');
const Trip = () =>
    import( /*webpackChunkName: "trip"*/ './views/trip/Trip.vue');
const Designated = () =>
    import( /*webpackChunkName: "designated"*/ './views/designated/Designated.vue');
const Errand = () =>
    import( /*webpackChunkName: "errand"*/ './views/errand/Errand.vue');
const Movehouse = () =>
    import( /*webpackChunkName: "movehouse"*/ './views/movehouse/movehouse.vue');
const Specialcar = () =>
    import( /*webpackChunkName: "specialcar"*/ './views/specialcar/specialcar.vue');
const CarList = () =>
    import( /*webpackChunkName: "carList"*/ './views/carList/CarList.vue');
const DriverList = () =>
    import( /*webpackChunkName: "driverList"*/ './views/driverList/DriverList.vue');
const Setting = () =>
    import( /*webpackChunkName: "setting"*/ './views/usersetting/Setting.vue');
const Userinfo = () =>
    import( /*webpackChunkName: "userinfo"*/ './views/userinfo/Userinfo.vue');
const Points = () =>
    import( /*webpackChunkName: "points"*/ './views/points/Points.vue');
const Phone = () =>
    import( /*webpackChunkName: "phone"*/ './views/phone/Phone.vue');
const WorkOrder = () =>
    import( /*webpackChunkName: "phone"*/ './views/phone/workOrder.vue');
const Details = () =>
    import( /*webpackChunkName: "phone"*/ './views/phone/details.vue');
const Order = () =>
    import( /*webpackChunkName: "order"*/ './views/order/Order.vue');
const Coupon = () =>
    import( /*webpackChunkName: "coupon"*/ './views/coupon/Coupon.vue');
const Exchangecoupon = () =>
    import( /*webpackChunkName: "exchangecoupon"*/ './views/exchangecoupon/Exchangecoupon.vue');
const Searchaddress = () =>
    import( /*webpackChunkName: "searchaddress"*/ './views/searchaddress/Searchaddress.vue');
const Search = () =>
    import( /*webpackChunkName: "Search"*/ './views/searchaddress/Search.vue');
const Wait = () =>
    import( /*webpackChunkName: "wait"*/ './views/wait/Wait.vue');
const Sharedroute = () =>
    import( /*webpackChunkName: "sharedroute"*/ './views/sharedroute/sharedroute.vue');
const Invite = () =>
    import( /*webpackChunkName: "invite"*/ './views/invite/Invite.vue');
const InviteList = () =>
    import( /*webpackChunkName: "inviteList"*/ './views/invite/InviteList.vue');
const OldUser = () =>
    import( /*webpackChunkName: "olduser"*/ './views/olduser/olduser.vue');
const NewUser = () =>
    import( /*webpackChunkName: "newuser"*/ './views/newuser/newuser.vue');
// const Finish = () =>
//   import(/*webpackChunkName: "finish"*/ './views/finish/Finish.vue');
const Qrcode = () =>
    import( /*webpackChunkName: "qrcode"*/ './views/qrcode/qrcode.vue');
const Announce = () =>
    import( /*webpackChunkName: "announce"*/ './views/announce/announce.vue');
const AnnounceDetail = () =>
    import( /*webpackChunkName: "announceDetail"*/ './views/announce/announceDetail.vue');
const AdvertiseDetail = () =>
    import( /*webpackChunkName: "advertiseDetail"*/ './views/advertiseDetail/advertiseDetail.vue');
const Protocol = () =>
    import( /*webpackChunkName: "protocol"*/ './views/protocol/protocol.vue');
const im = () =>
    import( /*webpackChunkName: "im"*/ './views/im/im.vue');

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)



const router = new Router({
    // mode: 'history',
    mode: 'hash',
    routes: [
        // eg. 编译APP 注释两个授权路由 放开登录1路由 ;   
        // 编译公众号 注释登录1 放开两授权
        // 登录1
      /*     {
            path: '/',
            name: 'login',
           component: Login,
        },
		 */
		  
		  
        // 登录
        {
           path: '/login',
          name: 'login',
           component: Login,
       },
		
		
        //  授权
         {
            path: '/oauth',
            name: 'oauth',
            component: OAuth,
       },
         // 授权
        {
           path: '/inviteoauth/:idcode',
           name: 'inviteoauth',
           component: InviteOauth,
        },
		  
		
        // 邀请登录
        {
            path: '/invitelogin',
            name: 'invitelogin',
            component: Invitelogin,
        },
        // 司机验证
        {
            path: '/driver',
            name: 'driver',
            component: driver,
        },
        // 分类
        {
            path: '/home',
            name: 'home',
            component: Home,
            meta: {
                requireAuth: true,
            },
        },
        // 出行
        {
            path: '/trip',
            name: 'trip',
            component: Trip,
            meta: {
                requireAuth: true,
            },
        },
        // 代驾
        {
            path: '/designated',
            name: 'designated',
            component: Designated,
            meta: {
                requireAuth: true,
            },
        },
        // 跑腿
        {
            path: '/errand',
            name: 'errand',
            component: Errand,
            meta: {
                requireAuth: true,
            },
        },
        // 搬家
        {
            path: '/movehouse',
            name: 'movehouse',
            component: Movehouse,
            meta: {
                requireAuth: true,
            },
        },
        // 专车
        {
            path: '/specialcar',
            name: 'specialcar',
            component: Specialcar,
            meta: {
                requireAuth: true,
            },
        },
        // 车型列表
        {
            path: '/carList/:dccode',
            name: 'carList',
            component: CarList,
            meta: {
                requireAuth: true,
            },
        },
        // 司机列表
        {
            path: '/driverList/:dccode',
            name: 'driverList',
            component: DriverList,
            meta: {
                requireAuth: true,
            },
        },
        // 设置
        {
            path: '/setting',
            name: 'setting',
            component: Setting,
            meta: {
                requireAuth: true,
            },
        },
        // 我的二维码
        {
            path: '/qrcode',
            name: 'qrcode',
            component: Qrcode,
            meta: {
                requireAuth: true,
            },
        },
        // 个人信息
        {
            path: '/userinfo',
            name: 'userinfo',
            component: Userinfo,
            meta: {
                requireAuth: true,
            },
        },
        // 我的积分
        {
            path: '/points',
            name: 'points',
            component: Points,
            meta: {
                requireAuth: true,
            },
        },
        // 联系客服
        {
            path: '/workOrder',
            name: 'workOrder',
            component: WorkOrder,
            meta: {
                requireAuth: true,
            },
        },
        // 联系客服
        {
            path: '/phone',
            name: 'phone',
            component: Phone,
            meta: {
                requireAuth: true,
            },
        },
        // 联系客服详情
        {
            path: '/details',
            name: 'details',
            component: Details,
            meta: {
                requireAuth: true,
            },
        },
        // 邀请好友
        {
            path: '/invite',
            name: 'invite',
            component: Invite,
            meta: {
                requireAuth: true,
            },
        },
        // 邀请好友列表
        {
            path: '/inviteList',
            name: 'inviteList',
            component: InviteList,
            meta: {
                requireAuth: true,
            },
        },
        // 老用户邀请页面
        {
            path: '/olduser',
            name: 'olduser',
            component: OldUser,
            meta: {
                requireAuth: true,
            },
        },
        // 新用户邀请页面
        {
            path: '/newuser',
            name: 'newuser',
            component: NewUser,
            meta: {
                requireAuth: true,
            },
        },
        // 我的订单
        {
            path: '/order',
            name: 'order',
            component: Order,
            meta: {
                requireAuth: true,
            },
        },
        // // 结束订单
        // {
        //   path: '/finish',
        //   name: 'finish',
        //   component: Finish,
        //   meta: {
        //     requireAuth: true,
        //   },
        // },
        // 优惠券
        {
            path: '/coupon',
            name: 'coupon',
            component: Coupon,
            meta: {
                requireAuth: true,
            },
        },
        // 优惠码兑换
        {
            path: '/exchangecoupon',
            name: 'exchangecoupon',
            component: Exchangecoupon,
            meta: {
                requireAuth: true,
            },
        },
        // 搜索地址-商圈 我的地址 历史记录
        {
            // type 订单类型
            // address 位置类型  0.添加地址 1.起点 2.终点 3.转车地址
            path: '/search/:type/:address',
            name: 'search',
            component: Search,
            meta: {
                requireAuth: true,
            },
        },
        // 搜索地址
        {
            // type 订单类型
            // address 位置类型 0.添加地址 1.起点 2.终点 3.转车地址
            path: '/searchaddress/:type/:address',
            // path: '/searchaddress',
            name: 'searchaddress',
            component: Searchaddress,
            meta: {
                requireAuth: true,
            },
        },
        // 等待应答
        {
            //orderId
            //type:订单类型:1.出行，2.代驾，3.取送件，4.代购
            //time:1.及时订单 2.预约单
            //status:1.等待司机接单 2.司机已接单，订单未开始 3.司机接驾中 4.订单行程进行中 5.订单结束 6.用户取消订单
            path: '/wait/:orderId/:type/:time/:status',
            name: 'wait',
            component: Wait,
            meta: {
                requireAuth: true,
            },
        },
        // 分享页面
        {
            //orderId
            //type:订单类型:1.出行，2.代驾，3.取送件，4.代购
            //time:1.及时订单 2.预约单
            //status:1.等待司机接单 2.司机已接单，订单未开始 3.司机接驾中 4.订单行程进行中 5.订单结束 6.用户取消订单
            path: '/sharedroute/:orderId',
            name: 'sharedroute',
            component: Sharedroute,
            meta: {
                requireAuth: true,
            },
        },
        // 公告
        {
            path: '/announce',
            name: 'announce',
            component: Announce,
            meta: {
                requireAuth: true,
            },
        },
        // 公告详情
        {
            path: '/announceDetail/:id',
            name: 'announceDetail',
            component: AnnounceDetail,
            meta: {
                requireAuth: true,
            },
        },
        // 广告详情
        {
            path: '/advertiseDetail/:id',
            name: 'advertiseDetail',
            component: AdvertiseDetail,
            meta: {
                requireAuth: true,
            },
        },
        // 用户协议
        {
            path: '/protocol/:id',
            name: 'protocol',
            component: Protocol,
        },
        // 司乘沟通
        {
            path: '/im',
            name: 'im',
            component: im,
        },
        // 其他路由
        {
            path: '',
            redirect: '/oauth',
        },
    ],
});

router.beforeEach((to, from, next) => {
    // next();
    if (to.matched.some(r => r.meta.requireAuth)) {
        if (!localStorage.token) {
            console.log("/login")
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                },
            });
        } else {
            if (document.URL.match(/\?.*#/)) {
                console.log("document.URL:", document.URL)
                let temp = document.URL.match(/\?.*#/)[0];
                console.log('temp：', temp)
                if (temp.match(/code=.*&/)) {
                    console.log("11--22--33--444")
                    // 解析Code
                    let code = temp.match(/=.*&/)[0];
                    let state = temp.match(/state=.*#/)[0];
                    console.log("state:", temp.match(/state=.*#/)[0])
                    code = code.substr(1, code.length); // 去掉 ?
                    code = code.substr(0, code.length - 1); // 去掉 #
                    state = state.substr(6, state.length);
                    state = state.substr(0, state.length - 1);
                    console.log("code:", code)
                    console.log("state:", state)
                    getopenId(code, state).then(res => {
                        // console.log("wxdata:", res.data)
                        if (res.data && res.data != null) {
                            setStore('wx', res.data)
                            //    window.localStorage.setItem('wx', res.data);
                            // localStorage.setItem('wx', JSON.stringify(res.data));
                        }
                    });
                    // localStorage.setItem('code', code);
                    setStore('code', code)
                    if (state != undefined && state != 'STATE')
                        setStore('state', state)
                    // localStorage.setItem('state', state);

                    // 重置URL(去掉code参数，避免重复调用)
                    window.history.pushState({},
                        0,
                        document.URL.replace(temp.substr(0, temp.length - 1), ''),
                    );
                    getopenId(code, state).then(res => {
                        // console.log("wxdata:", res.data)
                        if (res.data && res.data != null) {
                            setStore('wx', res.data)
                            //    window.localStorage.setItem('wx', res.data);
                            // localStorage.setItem('wx', JSON.stringify(res.data));
                        }
                    });
                }
                if (temp.match(/from=.*&/)) {
                    window.history.pushState({},
                        0,
                        document.URL.replace(temp.substr(0, temp.length - 1), ''),
                    );
                }
            }
            next();
        }
    } else {
        if (document.URL.match(/\?.*#/)) {
            let temp = document.URL.match(/\?.*#/)[0];
            if (temp.match(/code=.*&/)) {
                // 解析Code
                let code = temp.match(/=.*&/)[0];
                let state = temp.match(/state=.*#/)[0];

                code = code.substr(1, code.length); // 去掉 ?
                code = code.substr(0, code.length - 1); // 去掉 #
                state = state.substr(6, state.length);
                state = state.substr(0, state.length - 1);
                // localStorage.setItem('code', code);
                setStore('code', code)
                if (state != undefined && state != 'STATE')
                    // localStorage.setItem('state', state);
                    setStore('state', state)
                // 重置URL(去掉code参数，避免重复调用)
                window.history.pushState({},
                    0,
                    document.URL.replace(temp.substr(0, temp.length - 1), ''),
                );
                getopenId(code, state).then(res => {
                    console.log("getopenid_res:", res)
                    if (res.data && res.data != null) {
                        setStore('wx', res.data)
                        // localStorage.setItem('wx', res.data);
                        // localStorage.setItem('wx', JSON.stringify(res.data));
                    }
                });
            }
            if (temp.match(/from=.*&/)) {
                window.history.pushState({},
                    0,
                    document.URL.replace(temp.substr(0, temp.length - 1), ''),
                );
            }
        }
        next();
    }
});

export default router;