<template>
  <div class="notice">
    <div
      class="notice-item"
      @click="$router.push(`/advertiseDetail/${ad.ID}`)"
      v-for="ad in adList"
      :key="ad.ID"
    >
      <img :src="ad.ADImg" alt />
    </div>
  </div>
</template>

<script>
// import { getAdvertise } from '@/service/'
// import { Toast } from 'vant';

export default {
  name: "ad",
  props: ["adList"],
  data() {
    return {
      // nearBy: 6
      // adList: []
    };
  },
  methods: {
    // async getBannerList () {
    //   let res = await getAdvertise()
    //   if (res.code == 1) {
    //     this.adList = res.data
    //     // console.log(res)
    //   } else {
    //     Toast(res.msg)
    //   }
    // }
  },
  mounted() {
    // this.getBannerList()
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";

.notice {
  // .size(690px, 240px);
  // margin: 30px auto;
  // border-radius: 20px;
  // background: #ffffff;
  // box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  // box-sizing: border-box;
  // // padding: 30px;
  // font-family: 'PingFangSC-Regular';
  // font-size: 30px;
  // color: #333333;

  &-item {
    // display: flex;
    // flex: 1;
    .size(690px, 240px);
    overflow: hidden;
    margin: 30px auto;
    border-radius: 20px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    // padding: 30px;
    font-family: "PingFangSC-Regular";
    font-size: 30px;
    color: #333333;
    // align-items: center;
    // justify-content: space-between;

    img {
      width: 690px;
      height: auto;
    }
  }
}
</style>