<template>
  <div>
    <div class="drawer" v-if="userInfo">
      <van-popup
        v-model="showDrawer"
        :overlay="true"
        position="left"
        @click-overlay="closeDrawer"
        class="drawer-box"
      >
        <div class="drawer-wrapper">
          <div class="drawer-header" @click="$router.push('/userinfo')">
            <div>
              <div class="drawer-username text-center">{{userInfo.WeiXin}}</div>
              <div class="drawer-usertel">{{userInfo.AreaCode}}{{userInfo.Tel}}</div>
            </div>
            <div class="drawer-icon-right"></div>
          </div>
          <div class="drawer-list">
            <div
              class="drawer-list-item"
              v-for="d in drawerList"
              :key="d.id"
              @click.prevent="$router.push(d.path)"
            >
              <div :class="['drawer-list-item-icon',d.imgClass]"></div>
              <div class="drawer-list-item-title">{{d.txt}}</div>
            </div>
          </div>

          <!-- <div class="">
            <div class="drawer-circle">
              <div class="drawer-code">
                <div ref="qrcode"
                     id="qrcode"></div>
                <img src="../../../public/code.png"
                     alt="">
              </div>
            </div>
          </div>-->
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { Popup } from "vant";
import { mapState } from "vuex";
import { getStore } from "../../lib/storage.js";

export default {
  name: "drawer",
  components: {
    [Popup.name]: Popup
  },
  data() {
    return {
      drawerList: [
        {
          id: 0,
          imgClass: "drawer-icon-order",
          path: "/order",
          txt: "我的订单"
        },
        {
          id: 1,
          imgClass: "drawer-icon-point",
          path: "/points",
          txt: "我的积分"
        },
        {
          id: 2,
          imgClass: "drawer-icon-coupon",
          path: "/coupon",
          txt: "优惠券"
        },
        {
          id: 3,
          imgClass: "drawer-icon-couponcode",
          path: "/exchangecoupon",
          txt: "优惠码"
        },
        {
          id: 4,
          imgClass: "drawer-icon-invite",
          path: "/invite",
          txt: "邀请好友"
        },
        {
          id: 5,
          imgClass: "drawer-icon-qrcode",
          path: "/qrcode",
          txt: "我的二维码"
        },
        {
          id: 8,
          imgClass: "drawer-icon-announce",
          path: "/announce",
          txt: "重要通知"
        },
        {
          id: 6,
          imgClass: "drawer-icon-phone",
          path: "/workOrder",
          txt: "联系客服"
        },
        {
          id: 7,
          imgClass: "drawer-icon-setting",
          path: "/setting",
          txt: "设置"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      userInfo: ({ userVal }) => userVal.userInfo,
      wxinfo: ({ userVal }) => userVal.wxinfo
    }),
    userInfo() {
      return JSON.parse(getStore("userInfo"));
    },
    showDrawer: {
      get() {
        return this.$attrs.showDrawer;
      },
      set() {}
    }
  },
  methods: {
    closeDrawer() {
      this.$emit("closeDrawer");
    }
  }
};
</script>

<style scoped lang="less">
@import "../../styles/mixin.less";

.drawer {
  overflow: hidden;
  height: 100%;
  &-box {
    // position: relative;
    width: 60%;
    height: 100%;
    // background: rgba(255, 255, 255, 1);
    // background: rgba(255, 255, 255, 1) url('../../assets/Path 2@2x.png') 0
    //   bottom no-repeat;
    // background-size: 650px 322px;
    overflow: hidden;
  }

  &-header {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    .size(450px, 380px);
    background: url("../../assets/drawer_header.png") 0 0 no-repeat;
    background-size: 650px 380px;
  }

  &-username {
    margin-bottom: 14px;
    font-size: 50px;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 72px;
  }

  &-usertel {
    font-size: 30px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 42px;
  }

  &-icon {
    &-right {
      .size(10px, 20px);
      margin-left: 14px;
      background: url("../../assets/arrow_right.png") 0 0 no-repeat;
      background-size: 10px 20px;
    }

    &-order {
      background: url("../../assets/drawer_order.png") center center no-repeat;
      background-size: 32px 40px;
    }
    &-point {
      background: url("../../assets/drawer_score.png") center center no-repeat;
      background-size: 40px 40px;
    }
    &-coupon {
      background: url("../../assets/drawer_coupon.png") center center no-repeat;
      background-size: 40px 30px;
    }
    &-couponcode {
      background: url("../../assets/drawer_couponcode.png") center center
        no-repeat;
      background-size: 40px 30px;
    }
    &-invite {
      background: url("../../assets/drawer_invite.png") center center no-repeat;
      background-size: 40px 32px;
    }
    &-qrcode {
      background: url("../../assets/drawer_qrcode.png") center center no-repeat;
      background-size: 36px 36px;
    }
    &-setting {
      background: url("../../assets/drawer_setting.png") center center no-repeat;
      background-size: 40px 40px;
    }
    &-phone {
      background: url("../../assets/drawer_phone.png") center center no-repeat;
      background-size: 60px 60px;
    }
    &-announce {
      background: url("../../assets/drawer-icon-announce.png") center center no-repeat;
      background-size: 44px 42px;
    }
  }

  &-list {
    margin: 15px auto 32px;

    &-item {
      display: flex;
      flex: 1;
      padding-left: 60px;
      margin-bottom: 40px;

      &-icon {
        .size(42px, 42px);
        // display: flex;
        // flex: 1;
        // align-items: center;
        // justify-content: center;
        margin-right: 30px;
      }

      &-title {
        font-size: 30px;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 42px;
      }
    }
  }

  &-code {
    .size(108px, 108px);
    // margin: 52px auto;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  &-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    .size(200px, 200px);
    bottom: 110px;
    left: 220px;
    border-radius: 50%;
    border: 10px solid #fff;
    background: #f8f8f8;
    box-sizing: border-box;
  }
}
</style>
