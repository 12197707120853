import axios from './http';
import {
    $upload
} from './http';
import requestUrl from './requestUrl';
// import {
//     API_KEY
// } from '@/config';
import $ from 'jquery'
import {
    CUSTOM_VERSION
} from "@/config/";
/**
 * 经纬度换取地址信息
 * @param {string} latlng 经纬度
 * @param {string} key
 */
// export const locationAddress = (latlng, key = API_KEY, UIDCode, IP) => {
//     return axios({
//         url: requestUrl.locationAdd,
//         method: 'get',
//         params: {
//             latlng,
//             key,
//             UIDCode,
//             IP
//         },
//     });
// };
/**
 * 公告
 * 
 */
export const noticeContent = (type, Umid) => {
    return axios({
        url: requestUrl.noticeContent,
        method: 'post',
        params: {
            type,
            Umid
        },
    });
};

/**
 * 修改司机openid
 *
 */
export const UpdateOpenid = (tel, openid) => {
    return axios({
        url: requestUrl.UpdateOpenid,
        method: 'post',
        params: {
            tel,
            openid
        }
    })
};

/**
 * 上传极光IM用户密码
 * 
 */
export const setjsIM = (_parms) => {
    console.log('aadfa:_parms', _parms)
    return axios({
        url: requestUrl.setjsIM,
        method: 'post',
        params: _parms,
    });
};

/**
 * 获取后端谷歌地图信息
 * @param {string} url 需要调用的google map api url
 */
export const getGoogleMapInfo = (Url, UIDCode, Keys, IP) => {
    return axios({
        url: requestUrl.googleMapApi,
        method: 'post',
        data: {
            Url,
            UIDCode,
            Keys,
            IP
        },
    });
};


/**
 * 再次获取后端谷歌地图信息
 * @param {string} url 需要调用的google map api url
 */
export const GetGoogleApCity = (url) => {
    // console.log("requestUrl:", requestUrl)
    // console.log("urlurlurl:", url)
    return axios({
        url: requestUrl.GetGoogleApCity,
        method: 'get',
        params: {
            url
        },
    });
};


/**
 * 再次获取后端谷歌地图信息
 * @param {string} url 需要调用的google map api url
 */
export const WxGoogleApiCity = (key, lon, lat) => {
    console.log("key, lon, lat:", key, lon, lat)
    return axios({
        url: requestUrl.WxGoogleApiCity,
        method: 'get',
        params: {
            key,
            lon,
            lat
        },
    });
};
/**
 * 谷歌定位
 * @param {string} key
 */
// export const googleGeolocate = () => {
//     return axios({
//         url: requestUrl.googleGeolocate,
//         method: 'post',
//         data: {},
//     });
// };

// /**
//  * 搜索地址信息,之前注释的
//  * @param {string} query 查询内容
//  * @param {string} key google map api_key
//  */
// export const searchAdd = (query, key = API_KEY) => {
//   return axios({
//     url: requestUrl.searchAdd,
//     method: 'get',
//     params: {
//       query,
//       key,
//     },
//   });
// };
// export const autocompleteAdd = (input, latlng, API_KEY2) => {
//     return axios({
//         url: requestUrl.autocomplete + '?input=' + input + '&location=' + latlng + '&radius=50000&key=' + API_KEY2,
//         method: 'get',
//         params: {

//         },
//     });
// };

// /**
//  * 搜索框自动完成,之前注释的
//  * @param {*} input 输入搜索框字符
//  * @param {*} key apikey
//  */
export const geocodeAdd = (data, success, fail) => {
    return $.ajax({
        url: requestUrl.geocode,
        type: "GET",
        dataType: 'json',
        data: data,
        success: function (res) {
            console.log("success--", res);
            success(res);
            return res
        },
        error: function (a) {
            console.log("error--", a);
            console.log(a);
            fail(a);
            return a
        }
    });
};

/**
 * 获取openid
 * @param {string} code 微信返回code
 * @param {string} state state参数
 */
export const getopenId = (code, state = '') => {
    return axios({
        url: requestUrl.getopenId,
        method: 'get',
        params: {
            code,
            state,
        },
    });
};

/**
 * 获取验证码
 * @param {string} Tel the telphonenumber include pre-telphone number eg:+8613888888888
 * @param {string} Type the type of getcode 1:custom 2:driver
 */
export const getVerificationCode = (Tel, Type, ticket, randstr, ConInfo = '公众号') => {
    return axios({
        url: requestUrl.getVerificationCode,
        method: 'post',
        data: {
            Tel,
            Type,
            ticket,
            randstr,
            ConInfo
        },
    });
};

/**
 * 修改评论
 */

export const updateComment = (Umid, Pmid, StarCount, Orderid, LabelInfo, CommentContent) => {
    return axios({
        url: requestUrl.updateComment,
        method: 'post',
        data: {
            Umid,
            Pmid,
            StarCount,
            Orderid,
            LabelInfo,
            CommentContent
        },
    });
};
/**
 * 关闭评论
 */

export const closeComment = (ID, State = 1) => {
    return axios({
        url: requestUrl.closeComment,
        method: 'post',
        data: {
            ID,
            State
        },
    });
};
/**
 * 查询评论
 */
export const queryComment = Orderid => {
    return axios({
        url: requestUrl.queryComment,
        method: 'get',
        params: {
            Orderid
        },
    });
};

/**
 * 弹出评论
 */
export const popupComment = Umid => {
    return axios({
        url: requestUrl.popupComment,
        method: 'get',
        params: {
            Umid
        },
    });
};
/**
 * 查询优惠券
 */
export const checkCoupon = userid => {
    return axios({
        url: requestUrl.checkCoupon,
        method: 'post',
        params: {
            userid
        },
    });
};
/**
 * 领取优惠券
 */
export const getCoupon = (userid, counptid) => {
    return axios({
        url: requestUrl.getCoupon,
        method: 'post',
        params: {
            userid,
            counptid
        },
    });
};
/**
 * 删除优惠券
 */
export const deleteCoupon = (userid, counptid) => {
    return axios({
        url: requestUrl.deleteCoupon,
        method: 'post',
        params: {
            userid,
            counptid
        },
    });
};
// 输入兑换码兑换优惠券
export const exchangeCounptList = (userid, counptcode) => {
    return axios({
        url: requestUrl.exchangeCounptList,
        method: 'post',
        params: {
            userid,
            counptcode
        },
    });
};
//查询首页新优惠券弹窗
export const selectPopup = (userid) => {
    return axios({
        url: requestUrl.selectPopup,
        method: 'post',
        params: {
            userid,
        },
    });
};
//查询谷歌密钥
export const ApiKeys = (userid) => {
    return axios({
        url: requestUrl.ApiKeys,
        method: 'post',
        params: {
            userid,
        },
    });
};


/**
 * 获取国家代码前缀
 * @param {string} where
 */
export const getDicCountriesCodeList = (where = 'IsDefault=1') => {
    return axios({
        url: requestUrl.getCountry,
        method: 'get',
        params: {
            where,
        },
    });
};

/**
 * 注册
 * @param {*} IDCode 推荐用户id
 * @param {*} Tel 手机号码
 * @param {*} openid openid
 * @param {*} headimgurl 微信头像
 * @param {*} nickname 昵称
 * @param {*} Equipment 登录设备
 * @param {*} LoginType 登录端口类型
 * @param {*} IPInfo ip地址
 * @param {*} AreaCode 地区代码
 * @param {*} VerificationCode 验证码
 * @param {*} ClientID 推送消息
 */
export const login = (
    IDCode,
    Tel,
    ShareID,
    openid,
    headimgurl,
    nickname,
    Equipment,
    LoginType,
    IPInfo = '',
    AreaCode,
    VerificationCode,
    ClientID = '0',
) => {
    return axios({
        url: requestUrl.login,
        method: 'post',
        data: {
            IDCode,
            Tel,
            ShareID,
            openid,
            headimgurl,
            nickname,
            Equipment,
            LoginType,
            IPInfo,
            AreaCode,
            VerificationCode,
            ClientID,
        },
    });
};

//退出登录

export const logOut = id => {
    return axios({
        url: requestUrl.logOut,
        method: 'post',
        params: {
            id,
        },
    });
};
/**
 * 查询用户信息和订单状态信息
 * @param {string} Umid 用户id
 */
export const queryUserInfo = Umid => {
    return axios({
        url: requestUrl.queryUserInfo,
        method: 'get',
        params: {
            Umid,
        },
    });
};

/**
 * 获取我的订单列表
 * @param {string} Umid 用户id
 * @param {string} OrderType 订单类型
 */
export const getOrderList = (Umid, OrderType) => {
    return axios({
        url: requestUrl.orderList,
        method: 'get',
        params: {
            Umid,
            OrderType,
        },
    });
};

/**
 * 发送消息
 * @param {Number} OrderId 订单id
 * @param {Number} Pmid 司机ID，司机发送消息时Pmid就传司机ID， Umid传0
 * @param {Number} Umid 乘客ID，乘客发送消息时Umid就传乘客ID， Pmid传0
 * @param {string} Content 发送的内容
 */
export const newsetmsg = formdata => {
    return axios({
        url: requestUrl.newsetmsg,
        method: 'post',
        data: formdata
    });
};

/**
 * 获取聊天内容
 * @param {Number} id 订单id
 * @param {Number} selectid 0=查询此订单所有的聊天记录 1=查询最近一条聊天记录
 * 响应
 * ID 聊天记录ID
 * OrderId 对应的订单ID
 * Pmid 司机ID，如果大于0就代表是司机发送的信息
 * Umid 乘客ID，如果大于0就代表是乘客发送的信息
 * Content 聊天的内容
 * CreateDate 聊天的时间
 */
export const newgetmsg = (id, selectid) => {
    if (!id) {
        return;
    }
    return axios({
        url: requestUrl.newgetmsg,
        method: 'get',
        params: {
            id,
            selectid
        },
    });
};

/**
 * 获取用户地址列表
 * @param {string} id 用户id
 */
export const getUserAddress = id => {
    return axios({
        url: requestUrl.userAddress,
        method: 'get',
        params: {
            id,
        },
    });
};

/**
 * 提交订单
 * @param {object} formdata 下单参数对象
 */
export const submitOrder = formdata => {
    return axios({
        url: requestUrl.submitOrder,
        method: 'post',
        data: formdata,
    });
};

/**
 * 提交订单(带图片)
 * @param {object} formdata 下单参数对象
 */
export const submitImageOrder = formdata => {
    return $upload({
        url: requestUrl.submitOrder,
        method: 'post',
        data: formdata,
    });
};

/**
 * 起点坐标周围车辆信息列表
 * @param {object} usergeo 用户设置的起点坐标
 */
export const getCarList = (usergeo, type) => {
    return axios({
        url: requestUrl.getCarListAround,
        method: 'post',
        data: {
            usergeo,
            type
        },
    });
};

/**
 * 获取优惠券列表
 * @param {*} Uid
 */
export const getCouponList = (Uid, Type = '0') => {
    return axios({
        url: requestUrl.getCouponList,
        method: 'get',
        params: {
            Uid,
            Type,
        },
    });
};

/**
 * 查询价格
 * @param {string} OrderType 订单类型
 * @param {object} StratGeo 起点坐标
 * @param {object} EndGeo 终点坐标
 */
export const getOrderPay = (OrderType, StratGeo, EndGeo, DcCode, AppointmentTime) => {
    console.log('AppointmentTime:', AppointmentTime)
    return axios({
        url: requestUrl.getOrderPay,
        method: 'post',
        data: {
            OrderType,
            StratGeo,
            EndGeo,
            DcCode,
            AppointmentTime,
        },
    });
};

/**
 * 获取问答专区数据
 */
export const UserDicCategorysList = (type = '1') => {
    return axios({
        url: requestUrl.UserDicCategorysList,
        method: 'get',
        data: {
            type
        },
    });
};

/**
 * 乘客提问或意见（提交工单）
 * @param {string} Umid 乘客ID
 * @param {string} Infos 工单内容
 * @param {string} TitleMsg 标题
 * @param {Array} Imgs 图片
 */
export const AddUserMessageInfo = (_parms) => {
    return axios({
        url: requestUrl.AddUserMessageInfo,
        method: 'post',
        data: _parms,
    });
};

/**
 * 乘客获取工单列表
 * @param {string} Umid 乘客ID
 */
export const UserMessageList = (Umid) => {
    return axios({
        url: requestUrl.UserMessageList,
        method: 'post',
        data: {
            Umid
        },
    });
};

/**
 * 多个位置计算价格
 * @param {string} OrderType 订单类型
 * @param {object} url 除起点和终点外的位置集合
 * @param {date} AppointmentTime 预约时间，预约单传预约的时间，及时单传空值
 */
export const getGoogleApi = (OrderType, url, AppointmentTime, IsAssign) => {
    // console.log("OrderType :", OrderType)
    // console.log("url :", url)
    // console.log("IsAssign:", IsAssign)
    // console.log("AppointmentTime :", AppointmentTime)
    let time = AppointmentTime ? AppointmentTime : '';
    return axios({
        url: requestUrl.getGoogleApi,
        method: 'post',
        data: {
            OrderType,
            url,
            time
        },
    });
};
/**
 * 通过订单id查询订单信息
 * @param {string} ID 订单id
 */
export const getOrderDetailByOrderId = ID => {
    return axios({
        url: requestUrl.getOrderDetail,
        method: 'get',
        params: {
            ID,
        },
    });
};



/**
 * 获取司机乘客聊天记录
 */
export const huanxiong_chat = (action = 'chat', LoginType = 'android', Equipment = 'android', umName, pmName, CreateDateS, Umid, Pmid) => {
    return axios({
        url: requestUrl.huanxiong_chat,
        method: 'get',
        params: {
            action,
            LoginType,
            Equipment,
            umName,
            pmName,
            CreateDateS,
            Umid,
            Pmid
        },
    });
};

/**
 * 追加小费
 * @param {string} ID 订单id
 * @param {string} Gratuity 追加小费数额
 */
export const addTips = (ID, Gratuity, DcCode) => {
    return axios({
        url: requestUrl.addTips,
        method: 'post',
        data: {
            ID,
            Gratuity,
            DcCode,
        },
    });
};

/**
 * 取消订单
 * @param {string} ID 订单id
 * @param {string} State 取消订单代号 7
 */
export const cancelOrder = (ID, ThisState, Anomalymanager, State = '7') => {
    return axios({
        url: requestUrl.cancelOrder,
        method: 'post',
        data: {
            ID,
            ThisState,
            State,
            Anomalymanager,
        },
    });
};

/**
 * 获取汇率
 */
export const getExchangeRate = () => {
    return axios({
        url: requestUrl.exchangeRate,
        method: 'get',
        params: {},
    });
};

/**
 * 查询用户搜索历史记录
 * @param {string} Umid 用户id
 */
export const getSearchHistoryList = Umid => {
    return axios({
        url: requestUrl.searchHistory,
        method: 'get',
        params: {
            Umid,
        },
    });
};

getversion

/**
 * 获取版本号
 */
export const getversion = () => {
    return axios({
        url: requestUrl.getversion,
        method: 'get',
        params: {},
    });
};


/**
 * 查询著名商圈
 * @param {string} Umid 用户id
 */
export const getBusinessDistrictList = (umid, version = CUSTOM_VERSION) => {
    return axios({
        url: requestUrl.businessDistrict,
        method: 'post',
        params: {
            version,
            umid,
        },
    });
};

/**
 * 添加用户地址
 * @param {string} Region 用户坐标
 * @param {string} Address 地址详情
 * @param {string} Umid 用户id
 */
export const addUserAddress = (Region, Address, Umid) => {
    return axios({
        url: requestUrl.addUserAddress,
        method: 'post',
        data: {
            Region,
            Address,
            Umid,
        },
    });
};

/**
 * 删除用户地址
 * @param {string} Umid 用户id
 * @param {string} type 1：删除用户全部地址；2：删除一个地址（需要给地址ID）
 * @param {string} ressid 地址ID（删除全部可以不给）
 */
export const delUserAddress = (Umid, type, ressid = null) => {
    return axios({
        url: requestUrl.delUserAddress,
        method: 'post',
        data: {
            Umid,
            type,
            ressid,
        },
    });
};

/**
 * 查询被邀请
 * @param {string} ID 用户id
 */
export const postinvite = userid => {
    return axios({
        url: requestUrl.inviteInfo,
        method: 'post',
        params: {
            userid,
        },
    });
};

/**
 * 微信签名串
 * @param {string} url 加密url地址
 */
export const wxsignature = url => {
    return axios({
        url: requestUrl.wxjssdk,
        method: 'post',
        data: {
            url,
        },
    });
};

/**
 * 获取公告
 */
export const getNotice = (Type = '3') => {
    return axios({
        url: requestUrl.notice,
        method: 'get',
        params: {
            Type
        },
    });
};
/**
 * 通知
 * 
 */
export const SelectEventList = () => {
    return axios({
        url: requestUrl.SelectEventList,
        method: 'post',
        params: {
            type: 1
        },
    });
};

/**
 * 获取广告列表
 */
export const getAdvertise = () => {
    return axios({
        url: requestUrl.advertise,
        method: 'get',
        params: {},
    });
};

/**
 * 获取公告详情
 */
export const getNoticeDetail = ID => {
    return axios({
        url: requestUrl.noticeDetail,
        method: 'get',
        params: {
            ID,
        },
    });
};

/**
 * 获取公告详情
 */
export const getAdvertiseDetail = ID => {
    return axios({
        url: requestUrl.advertiseDetail,
        method: 'get',
        params: {
            ID,
        },
    });
};

/**
 * 修改用户信息
 */
export const updateUserInfo = (WeiXin, ID) => {
    return axios({
        url: requestUrl.updateUserInfo,
        method: 'post',
        data: {
            WeiXin,
            ID,
        },
    });
};

/**
 * 获取协议内容
 */
export const getProtocol = DcCode => {
    return axios({
        url: requestUrl.protocol,
        method: 'get',
        params: {
            DcCode,
        },
    });
};