import {
    CHANGE_READY_STATUS,
    GET_COUNTRY_CODE_LIST,
    GET_USER_LOCATION,
    INIT_USER_LOCATION,
    SET_START_PLACE,
    SET_END_PLACE,
    SET_TRANSFER_PLACE,
    GET_CARLIST_AROUND,
    SET_PEOPLE_NUM,
    SELECT_CAR,
    GET_SEARCH_HISTORY,
    GET_BUSINESS_DISTRICT,
    GET_RATE_NUM,
    SET_ACTIVEITEMID_NUM,
    SET_ERRANDID,
    SET_HOMEACTIVEID,
    CLEAR_STARTADDRESS,
    CLEAR_POS,
    SET_SHOW_ORDER,
    CHANGE_QUERY_HISTORY,
    SET_MSG_LIST,
    SET_MSGNUMS,
    SET_ORDER_ID
} from "../mutation-types";
import {
    getStore,
    setStore,
    removeStore
} from "../../lib/storage";
import {
    getDicCountriesCodeList,
    getCarList,
    getGoogleMapInfo,
    getSearchHistoryList,
    getBusinessDistrictList,
    getExchangeRate,

} from "@/service/";
import {
    CUSTOM_VERSION
} from "@/config/";
import requestUrl from "@/service/requestUrl";
import {
    API_KEY
} from "@/config";
// import $ from 'jquery'
import { Popup, Toast, Loading } from "vant";
const state = {
    tips: 1,
    readyGo: true,
    // country list and tel pre list
    countryCodeList: [{
        ID: 32,
        TimeDifference: -780,
        IsDefault: 1,
        ByCountriesName: "Canada",
        CountriesName: "加拿大",
        DomainAbbreviation: "CA",
        TelephoneCode: "+1"
    },
    {
        ID: 37,
        TimeDifference: 0,
        IsDefault: 1,
        ByCountriesName: "China",
        CountriesName: "中国",
        DomainAbbreviation: "CN",
        TelephoneCode: "+86"
    }
    ],
    // 初始化用户坐标
    latlng: null,
    // geocode the latlng
    startAddress: "",
    startGeoList: '',
    startGeo: "",
    // geocode the latlng
    endAddress: "",
    endGeo: "",
    // // 起点信息
    // tripStartPlace: '',
    // // 终点信息
    // tripEndPlace: '',
    transferGeo: [],
    // 未读消息数量
    msgnums: 0,
    orderId: 0,
    // 车辆列表
    carList: null,
    // 乘客人数
    peopleNum: 1,
    // 预约和即时单索引
    activeItemId: 0,
    // 取送件，代购索引
    errandid: 0,
    homeActiveId: 0,
    // 选中的车辆
    selectedCar: null,
    searchHitory: [],
    // 商圈
    businessDistrict: [],
    // 兑换比例
    rateNum: 0,
    showOrder: false,
    queryHistory: [],
    msglist: JSON.parse(getStore("msglist")) ? JSON.parse(getStore("msglist")) : [],
    // 周围车辆type列表值
    typeList: [{
        id: 0,
        typename: "trip",
        typenumber: "100101"
    },
    {
        id: 1,
        typename: "designated",
        typenumber: "100102"
    },
    {
        id: 2,
        typename: "errand",
        typenumber: "100103"
    }
    ]
};

const mutations = {
    [CHANGE_READY_STATUS](state) {
        state.readyGo = false;
    },

    [GET_COUNTRY_CODE_LIST](state, countryCodeList) {
        setStore("countryCodeList", JSON.stringify(countryCodeList));
        state.countryCodeList = countryCodeList;
    },
    [SET_SHOW_ORDER](state, showOrder) {
        state.showOrder = showOrder;
    },
    [GET_USER_LOCATION](state, latlng) {
        setStore("pos", latlng);
        // console.log("GET_USER_LOCATION", latlng)
        // console.log("getStore('otheraddress'):", getStore('otheraddress'))
        let _otherad = getStore('otheraddress');
        if (_otherad) {
            setStore('otheraddress', false)
        } else {
            setStore("startGeo", latlng);
            state.latlng = latlng;
        }
    },
    [INIT_USER_LOCATION](state, location) {
        // console.log("INIT_USER_LOCATION_location:", location)
        state.startAddress = location.startAddress;
        state.startGeo = location.latlng ? location.latlng : location.startGeo;

        setStore('otheraddress', true)
        setStore("startAddress", location.startAddress);
        setStore("startGeo", location.latlng ? location.latlng : location.startGeo);
        // console.log("INIT_USER_LOCATION_pos")
        // setStore("pos", location.latlng ? location.latlng : location.startGeo);
    },
    [SET_START_PLACE](state, location) {
        state.startAddress = location.description;
        state.startGeo = location.latlng;
        console.log("locationlocation2:", location)
        setStore('otheraddress', true)
        setStore("startAddress", location.description);
        setStore("startGeo", location.latlng);
        // console.log("SET_START_PLACE_pos")
        setStore("pos", location.latlng);
    },
    [SET_END_PLACE](state, location) {
        state.endAddress = location.description ? location.description : "";
        state.endGeo = location.latlng ? location.latlng : "";
        if (location.description && location.latlng) {
            setStore("endAddress", location.description);
            setStore("endGeo", location.latlng);
        }
    },
    [SET_MSG_LIST](state, arr) {
        let msgname = "msglist";
        if (arr && arr.length > 0) {
            let _arr = state.msglist.concat(arr);
            let obj = {};
            _arr = _arr.reduce(function (item, next) {
                obj[next.CreateDate] ? '' : obj[next.CreateDate] = true && item.push(next);
                return item;
            }, []);
            state.msglist = _arr;
            setStore('msgnums', _arr.length)
        } else {
            state.msglist = [];
            state.msgnums = 0;
            setStore('msgnums', 0)
        }
        // removeStore(msgname)
        setStore(msgname, JSON.stringify(state.msglist))
    },
    [SET_MSGNUMS](state, data) {
        state.msgnums = data;
        setStore('msgnums', data)
    },
    [SET_ORDER_ID](state, data) {
        state.orderId = data;
        setStore('orderId', data)
    },

    [SET_TRANSFER_PLACE](state, locationlist) {
        state.transferGeo = locationlist;
        setStore('transfer', locationlist)
    },
    [SET_HOMEACTIVEID](state, homeActiveId) {
        state.homeActiveId = homeActiveId;
    },
    [CLEAR_STARTADDRESS](state, homeActiveId) {
        console.log("CLEAR_STARTADDRESS")
        try {
            state.startAddress = "";
        } catch (error) {
            console.log('CLEAR_STARTADDRESS_error:', error)
        }
        if (getStore("startAddress")) {
            removeStore("startAddress")
        }
        // let _state = { ...state }
        // console.log("state:", _state)
    },
    [CLEAR_POS](state, homeActiveId) {
        state.pos = "";
    },
    [GET_CARLIST_AROUND](state, carList) {
        state.carList = carList;
    },
    [SET_PEOPLE_NUM](state, peopleNum) {
        state.peopleNum = peopleNum;
    },
    [SET_ACTIVEITEMID_NUM](state, activeItemId) {
        state.activeItemId = activeItemId;
    },
    [SET_ERRANDID](state, errandid) {
        state.errandid = errandid;
    },
    [SELECT_CAR](state, selectedCar) {
        state.selectedCar = selectedCar;
    },
    [GET_SEARCH_HISTORY](state, searchHitory) {
        state.searchHitory = searchHitory;
    },
    [CHANGE_QUERY_HISTORY](state, queryHistory) {
        state.queryHistory = queryHistory;
    },
    [GET_BUSINESS_DISTRICT](state, businessDistrict) {
        state.businessDistrict = businessDistrict;
    },
    [GET_RATE_NUM](state, rateNum) {
        state.rateNum = rateNum;
    }
};

const actions = {
    // H5获取用户位置定位坐标
    geolocation({
        commit,
        dispatch
    }) {
        // dispatch("getgoogleMapPosition");
        // return
        return new Promise((resolve, reject) => {
            // console.log("11111")
            const u = navigator.userAgent, _msg = "定位失败，请输入起点位置"
            let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            // console.log('navigator.geolocation：', navigator.geolocation)
            // console.log("isiOS:", isiOS)
            // if (isiOS) {
            // return
            // } else {
            if (navigator.geolocation) {
                console.log('navigator.geolocation：', navigator.geolocation)
                navigator.geolocation.getCurrentPosition(
                    position => {
                        console.log("geolocation_ok")
                        let latlng = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                        commit(GET_USER_LOCATION, latlng);
                        setStore("pos", latlng);
                        if (state.startAddress) {
                            state.startAddress = "";
                        }
                        dispatch("geocodeLatLng");
                        resolve();
                    },
                    err => {
                        console.log(`geolocation failed:${err.message}`);
                        Toast(_msg)
                        reject();
                    }, {
                    enableHighAccuracy: true, //是否获取更精确的位置
                    timeout: 6000 //请求超时时间,单位ms；
                }
                );
            } else {
                dispatch("getgoogleMapPosition");
                console.log("222222")
                Toast(_msg)
                // Browser doesn't support Geolocation
                console.log(`Browser doesn't support Geolocation`);
                reject();
            }
            // }

        }).catch(err => {
            console.log(err);
        });
        // let res = await googleGeolocate()
        // console.log(res)
    },
    //获取谷歌地图定位
    getgoogleMapPosition({
        commit,
        dispatch
    }) {
        console.log("getgoogleMapPosition");

        if (!navigator.geolocation) {
            console.log("当前不支持html5定位位置");
            return;
        }
        let options =
        {
            enableHighAccuracy: true,//高精度定位
            timeout: 8000,//毫秒，超時時間,超時后調用error
            maximumAge: 0
        };
        navigator.geolocation.getCurrentPosition(function (position) {
            console.log('當前定位：', position);
            let latlng = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            console.log("latlng:", latlng)
            commit(GET_USER_LOCATION, latlng);
            setStore("pos", latlng);
            if (state.startAddress) {
                state.startAddress = "";
            }
            dispatch("geocodeLatLng");
        }, function (error) {
            Toast("请手动输入起点位置");
            console.log('error:', error)
            //处理错误
            switch (error.code) {
                case 1:
                    console.log("位置服务被拒绝");
                    // Toast("位置服务被拒绝");
                    break;
                case 2:
                    console.log("暂时获取不到位置信息");
                    // Toast("暂时获取不到位置信息");
                    break;
                case 3:
                    console.log("获取定位信息超时");
                    // Toast("获取定位信息超时");
                    break;
                default:
                    console.log("未知错误");
                    // Toast("未知错误");
                    break;
            }
        }, options);
    },
    geolocation2({
        commit,
        dispatch
    }) {
        console.log("geolocation2")
        var geolocation = new BMap.Geolocation();
        console.log("geolocation:", geolocation)
        geolocation.getCurrentPosition(function (r) {
            console.log("rrrrrrr:", r)
            if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                var mk = new BMap.Marker(r.point);
                console.log("r.point:", r.point)
                let currentLat = r.point.lat;
                let currentLon = r.point.lng;

                var x_PI = 3.14159265358979324 * 3000.0 / 180.0;
                var bd_lon = +r.point.lng;
                var bd_lat = +r.point.lat;
                var x = bd_lon - 0.0065;
                var y = bd_lat - 0.006;
                var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_PI);
                var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_PI);
                var gg_lng = z * Math.cos(theta);
                var gg_lat = z * Math.sin(theta)

                let latlng = {
                    lat: gg_lat,
                    lng: gg_lng,
                };
                console.log("latlng:", latlng)
                setStore("pos", latlng);
                commit(GET_USER_LOCATION, latlng);

                if (state.startAddress) {
                    state.startAddress = "";
                }
                // if (getStore("startAddress")) {
                //     setStore("startAddress", "");
                // }
                dispatch("geocodeLatLng");
                // SetCookie("curLat", currentLat, 1); //设置cookie
                // SetCookie("curLng", currentLon, 1); //设置cookie           
                var pt = new BMap.Point(currentLon, currentLat);
                var geoc = new BMap.Geocoder();
                geoc.getLocation(pt, function (rs) {
                    var addComp = rs.addressComponents;
                    console.log("addComp:", addComp)
                    // SetCookie("curLat", currentLat, 1); //设置cookie
                    // SetCookie("curLng", currentLon, 1); //设置cookie

                    var city = addComp.city;
                    var addComp = rs.addressComponents;
                    var texts = addComp.district + "-" + addComp.street + "-" + addComp.streetNumber;
                    //获取地理位置成功，跳转
                })
            }
        })
    },
    changeReadyStatus({
        commit
    }) {
        commit(CHANGE_READY_STATUS);
    },
    // 获取国家列表
    async getCountry({
        commit
    }) {
        let res = await getDicCountriesCodeList();
        if (res) {
            commit(GET_COUNTRY_CODE_LIST, res.data);
        }
    },
    // 经纬度坐标值换位置描述信息
    async geocodeLatLng({
        commit,
        dispatch
    }) {
        if (state.startAddress) {
            return;
        } else {
            if (getStore("pos") == null || getStore("pos") == "") {
                await dispatch("geolocation");
            }
            let userGeo = await JSON.parse(getStore("pos"));
            console.log("userGeo:", userGeo)
            if (!userGeo.lat && !userGeo.lng) {
                return
            }
            let latlng = `${userGeo.lat},${userGeo.lng}`;

            let idCode = JSON.parse(getStore("userInfo")).IDCode;
            let ip = 101;
            let url = `${requestUrl.geocode}?latlng=${latlng}&key=${API_KEY}&IDCode=${idCode}&IP=${ip}`;
            let res = await getGoogleMapInfo(url, idCode, latlng, ip);
            res = JSON.parse(res.data);
            console.log("geocodeLatLng_res:", res)
            // 
            if (res.status == "OK") {
                let results = res.results;
                commit(INIT_USER_LOCATION, {
                    startAddress: results[0].formatted_address,
                    startGeo: userGeo
                });
            }
        }
    },
    // 起点坐标周围车辆
    async getCarListAround({
        state,
        commit
    }, args) {
        // console.log(args, state, commit);
        let res = await getCarList(...args);
        // let ip = "101";
        // let IDCode = JSON.parse(localStorage.getItem('userInfo')).IDCode;
        if (res && res.code == 1) {
            // commit(SELECT_CAR, '');
            if (state.selectedCar) {
                // console.log(state.selectedCar.ID);
                var resArr = [];
                res.data.forEach(item => {
                    // console.log(item.Pmid);
                    resArr.push(item.Pmid);
                });
                // console.log("resArr", resArr);
                // console.log(JSON.stringify(resArr));
                // console.log(
                //     JSON.stringify(resArr).indexOf(JSON.stringify(state.selectedCar.Pmid))
                // );
                if (
                    JSON.stringify(resArr).indexOf(
                        JSON.stringify(state.selectedCar.Pmid)
                    ) == -1
                ) {
                    commit(SELECT_CAR, "");
                }
            }
            // console.log(JSON.stringify(state.selectedCar));
            // console.log(JSON.stringify(res.data));
            if (
                JSON.stringify(res.data).indexOf(JSON.stringify(state.selectedCar)) < 0
            ) {
                // commit(SELECT_CAR, '');
            }
            // console
            //     .log
            // JSON.stringify(res.data).indexOf(JSON.stringify(state.selectedCar)),
            // ();

            // console.log(GET_CARLIST_AROUND, "----------", res.data);
            commit(GET_CARLIST_AROUND, res.data || []);
            // commit(SELECT_CAR, res.data[0] || '');
        }
    },
    // 获取搜索地址历史记录
    async getSearchHistory({
        commit
    }, args) {
        let res = await getSearchHistoryList(args);
        // console.log(res);
        if (res.code == 1) {
            if (res.data != null) {
                commit(GET_SEARCH_HISTORY, res.data);
            }
        }
    },
    // 获取著名商圈
    async getBusinessDistrict({
        commit
    }) {
        let umid = JSON.parse(getStore("userInfo")).ID,
            version = CUSTOM_VERSION;
        let res;
        let ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) !== "micromessenger") {
            //微信浏览器
            res = await getBusinessDistrictList(umid, version);
        } else {
            res = await getBusinessDistrictList(umid);
        }
        if (res.code == 1) {
            // console.log(res)
            if (res.data != null) {
                commit(GET_BUSINESS_DISTRICT, res.data);
            }
        }
    },
    // 兑换比例
    async exchangeRateHandler({
        commit
    }) {
        let res = await getExchangeRate();
        if (res.code == 1) {
            // console.log(res.data);
            // this.rateNum = res.data
            commit(GET_RATE_NUM, res.data.CanadianDollar);
        }
    }
};

const getters = {
    // 过滤车辆列表 大于乘车人数
    tripCarList: state => {
        if (state.carList !== null) {
            return state.carList.filter(
                item => item.MannedNumber >= parseFloat(state.peopleNum)
            )
        }
    },
    // 默认选中车辆
    defaultSelectedCar: (state, getters) => {
        if (getters.tripCarList) {
            return getters.tripCarList[0];
        }
    },
    // 历史记录
    searchHitoryList: state => {
        if (state.searchHitory) {
            return state.searchHitory.slice(0, 2);
        }
    },
    // queryHistoryList: state => {
    //     if (state.queryHistory) {
    //         return state.queryHistory;
    //     }
    // }
};

export default {
    // namespaced: true,
    state,
    mutations,
    actions,
    getters
};