import Vue from 'vue'
import Vuex from 'vuex'
import globalVal from './modules/global'
import pathVal from './modules/path'
import userVal from './modules/user'
import orderVal from './modules/order'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    msglist:[]
  },
  mutations: {

  },
  actions: {

  },
  modules:{
    globalVal,
    pathVal,
    userVal,
    orderVal
  }
})
