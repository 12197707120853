export function validateTel(pre, tel) {
    if (!tel) {
        return '请输入电话号码!';
    }
    if (!/^\d+$/g.test(tel)) {
        return '手机号码不正确!';
    }
    if (pre == '+86') {
        if (!/^1[3456789]\d{9}$/.test(tel)) {
            return '手机号码不正确!';
        }

    }
    if (pre == '+1') {
        if (!/^\d{10}$/.test(tel)) {
            return '手机号码不正确!';
        }
    }
    return true
}

export function validateTelCode(code) {
    if (!code) {
        return '请输入验证码!'
    }
    if (!(/^\d+$/.test(code))) {
        return '验证码格式错误!'
    }

    return true
}