// import { API_KEY } from '@/config';
export default {
    // 定位url
    // locationAdd: "https://maps.googleapis.com/maps/api/geocode/json",
    // google geolocate
    // googleGeolocate: `https://www.googleapis.com/geolocation/v1/geolocate?key=${API_KEY}`,
    //搜索地址
    // searchAdd: "https://maps.googleapis.com/maps/api/place/textsearch/json",
    // 服务端请求googleApi
    // googleMapApi: "Api/GoogleApi/PostGoogleApiWeixinList",
    googleMapApi: "Api/GoogleApi/PostGoogleApiList",
    GetGoogleApCity: "Api/GoogleApi/GetGoogleApCity",
    WxGoogleApiCity: "api/GoogleApi/WxGoogleApiCity",
    autocomplete: "https://maps.googleapis.com/maps/api/place/autocomplete/json",
    geocode: "https://maps.googleapis.com/maps/api/geocode/json",
    // openid
    getopenId: "Api/UserMainMore/GetUserMain",
    // 用户登录判断
    isLogin: "Api/UserMainMore/PostUserMain",
    // 注册/登录
    login: "Api/UserMainMore/PostAddCustomUserMain",
    // 查询用户信息
    queryUserInfo: "Api/OrderItemMore/GetOrderItemOrUserInfoList",
    // queryUserInfo:"Api/NoticeItemMore/PostFirstUserNotice",
    // 获取验证码
    getVerificationCode: "Api/PartnerMainMore/PostVerificationCode",
    // 上传极光IM用户密码
    setjsIM: "api/UserMainMore/JGIM",
    // 首页查看订单评论信息
    getUnrate: "Api/UserCommentPartnerMore/GetUserCommentPartnerData",
    // 国家列表
    getCountry: "Api/DicCountriesCodeMore/GetDicCountriesCodeList",
    // 我的订单
    orderList: "Api/OrderItemMore/GetOrderItemData",
    // 用户地址
    userAddress: "Api/UserAddressMore/GetUserAddressList",
    // 乘客评论
    customComment: "Api/UserCommentPartnerMore/PostAddCustomUserCommentPartner",
    // 广告
    advertise: "Api/ADItemMore/GetAD",
    // 公告
    // notice: "Api/NoticeItemMore/GetNotice",
    // notice:'Api/NoticeItemMore/PostFirstUserNotice',
    notice: "Api/NoticeItemMore/PostUserNotice",
    // 公告详情
    noticeDetail: "Api/NoticeItemMore/GetNoticeItemInfo",
    // noticeDetail:"/Api/NoticeItemMore/GetNoticeItemInfo",
    // 通知
    SelectEventList: "api/NoticeItemMore/SelectEventList",
    // 广告详情
    advertiseDetail: "Api/ADItemMore/GetADItemInfo",
    // 优惠券
    getCouponList: "Api/User_MoneyticketMore/GetUser_MoneyticketList",
    // 获取用户周围司机列表
    getCarListAround: "Api/ViewVehicleDynamicVehicleMainMore/QueryViewVehicleDynamicVehicleMainItem",
    // 查询订单价格
    getOrderPay: "Api/OrderItemMore/QueryOrderPay",
    // 多个位置计算价格
    getGoogleApi: "Api/GoogleApi/GoogleCityList",
    // 获取问答专区数据
    UserDicCategorysList: "api/DicCategorysMore/GetUserDicCategorysList",
    // 乘客提问或意见（提交工单）
    AddUserMessageInfo: "/Api/UserMessageMore/PostAddUserMessageInfos",
    // 乘客获取工单列表
    UserMessageList: "/Api/UserMessageMore/PostUserMessageList",
    // 追加小费
    addTips: "Api/orderitemmore/PostUpdateOrderItemGratuity",
    // 取消订单
    cancelOrder: "Api/orderitemmore/PostUpdateOrderItem",
    //提交订单
    submitOrder: "/Api/OrderItemMore/PostMultiplePushs",
    //按id查询订单信息
    getOrderDetail: "Api/ViewOrderItemPartnerMainMore/GetViewOrderItemPartnerMainInfo",
    //获取司机乘客聊天记录
    huanxiong_chat: "api/m/huanxiong_chat",
    // signalrUrl 推送
    // signalrUrl: "https://hxgzh.raccoontrip.com", //正式
    signalrUrl: "https://hxapi.dezj.net", //正式
    signalrUrl2: "https://panshengapi.duolalexuan.com/", //正式
    // messages 历史消息
    messages: "https://report.im.jpush.cn/v2",
    // 获取汇率
    exchangeRate: "Api/AppInfoMore/Handshake",
    // 查询历史记录
    searchHistory: "Api/OrderItemMore/GetOrderItem",
    // 著名商圈
    businessDistrict: "Api/HotregionTypeMore/PostHotregionTypeList",
    // 添加用户地址
    addUserAddress: "Api/UserAddressMore/PostAddUser",
    // 删除用户地址
    delUserAddress: "Api/UserAddressMore/PostDelUser",
    // 邀请页面
    inviteInfo: "Api/UserMainMore/InvitationCount",
    // 微信jssdk
    wxjssdk: "Api/DicGradeMore/WxJsapiqm",
    // 修改用户信息
    updateUserInfo: "Api/UserMainMore/PostUpdateCustomUserMain",
    // 获取协议内容
    protocol: "Api/ContentMainMore/GetContentMainData",
    //获取版本号
    getversion: "/Api/AppInfoMore/Version",
    //修改评论
    updateComment: "Api/UserCommentPartnerMore/PostUpdateUserCommentPartner",
    //查询订单评论
    queryComment: "Api/UserCommentPartnerMore/GetUserCommentPartnerInfo",
    //弹出评论
    popupComment: "Api/UserCommentPartnerMore/GetUserCommentPartnerNotInfo",
    //关闭评论
    closeComment: "Api/UserCommentPartnerMore/PostUpdateCloseUserCommentPartner",
    //公告
    // noticeContent: "Api/NoticeItemMore/GetNoticeItemList",
    noticeContent: "Api/NoticeItemMore/PostFirstUserNotice",
    // 修改司机openid
    UpdateOpenid: "api/PartnerMainMore/UpdateOpenid",
    //首页查询优惠券
    checkCoupon: "Api/UserMainMore/WaitFor",
    //首页领取优惠券
    getCoupon: "Api/UserMainMore/ExchangeCounpt",
    //删除优惠券
    deleteCoupon: "Api/UserMainMore/DeleteCounpt",
    //兑换码兑换
    exchangeCounptList: "Api/UserMainMore/ExchangeCounptList",
    //查询有无新优惠券
    selectPopup: 'Api/UserMainMore/SelectPopup',
    //查询邀请人数和获取的优惠券数量
    // invitationCount: 'Api/UserMainMore/InvitationCount'
    //获取密钥
    // ApiKeys: 'Api/AppInfoMore/SelectKeys',
    //
    //注销登录
    logOut: 'Api/UserMainMore/PostUserLogout',
    //发送消息
    newsetmsg: 'Api/ChatTable/AddChat',
    //获取聊天内容
    newgetmsg: 'Api/ChatTable/GetChat'
};