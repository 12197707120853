export default {
    // method: 'post',
    // 基础url前缀
    // baseURL: "https://hxgzh.raccoontrip.com", //正式
    baseURL: "https://hxapi.dezj.net", //正式
    baseURL2: "https://panshengapi.duolalexuan.com", //正式
    // baseURL: 'https://jinshouzhiapi.tuotuowang.cn',
    baseURL3: 'https://hxapitest.raccoontrip.com', //测试
    // baseURL4: 'https://hxapi.raccoontrip.com',
    // 请求头信息 
    // headers: {
    //   // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    //   Authtion: getStore('token'),
    //   random: '1'+JSON.parse(getStore('userInfo')).ID,
    //   Handsome: Math.floor(Date.now() / 1000),
    // },
    // 参数
    data: {},
    // 设置超时时间
    timeout: 6000,
    // 返回数据类型
    responseType: "json"
};