<template>
  <div>
    <nav class="navbar" :class="isStartWX && isWX?'navbarwx':'navbarapp'">
      <div class="navBox">
        <!-- the user info icon -->
        <div class="user"
             @click.stop.prevent="popUser" v-if="showicon"></div>
        <!-- scroll navbar box -->
        <div class="navScrollBox"
             ref="navScrollWraper">
          <!-- the real scroll element -->
          <div class="navList"
               ref="navList">
            <!-- the scroll nav list items v-for the navItems -->
            <div class="navItem"
                 v-for="(item,index) in navItems"
                 :key="index"
                 @click.stop.prevent="tab(index)">
              <div :class="{active:index==activeItemId}"
                   class="itemContent">{{item.name}}</div>
            </div>
          </div>
        </div>
        <!-- the tips icon used to tip custom adds -->
        <div class="info" @click="$router.push('/announce')" v-if="showicon">
          <div class="bage"
               v-if="bageNum>0">{{bageNum}}</div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import BScroll from 'better-scroll'
// import {getStore} from '../../lib/storage.js'

export default {
  name: 'navBar',
  // props: ['navItems', 'activeItemId'],
   props: {
    navItems: {
      type: Array,
      default: []
    },
    activeItemId:{
      type:Number,
      default:0
    },
    showicon:{
      type:Boolean,
      default:true
    },
    isStartWX:{
      type:Boolean,
      default:true
    }
   },
  data () {
    return {
      bageNum: 0,
      isWX:false
    }
  },
  computed: {

  },
  methods: {
    popUser () {
      this.$emit('popUserInfo')
    },
    tab (index) {
      this._adjust(index)
      this.$emit('tab', index)
      // console.log(index)
    },
    _initWidth () {
      let navList = this.$refs.navList
      let items = navList.children
      let width = 0
      for (let i = 0; i < items.length; i++) {
        width += items[i].clientWidth
      }
      navList.style.width = `${width + 1}px`
    },
    _initScroll () {

      let options = {
        click: true,
        scrollY: false,
        scrollX: true,
        scrollbar: false,
        bounce: true,
      }
      this.scroll = new BScroll(this.$refs.navScrollWraper, options)
    },
    _adjust (tabId) {
      const viewportWidth = this.$refs.navScrollWraper.clientWidth
      const tabListWidth = this.$refs.navList.clientWidth
      const minTranslate = Math.min(0, viewportWidth - tabListWidth)
      const middleTranslate = viewportWidth / 2
      const items = this.$refs.navList.children
      let width = 0
      let itemWidth = 0
      this.navItems.every((item, index) => {
        itemWidth = items[index].clientWidth
        if (item.id === tabId) {
          return false
        }
        width += items[index].clientWidth
        return true
      })
      let translate = middleTranslate - width
      translate = Math.max(minTranslate, Math.min(0, translate))
      let trans = 0
      if (tabId < 2) {
        trans = 0
      } else if (tabId > this.navItems.length - 2) {
        trans = this.navItems.length - 3
      } else {
        trans = tabId - 1
      }
      translate = trans * itemWidth
      this.$refs.navList.style.transform = `translateX(-${translate}px)`
      // this.$refs.navList.scrollTo(translate, 0, 300)
    },
  },
  mounted () {
    // setTimeout(() => {
    //   this._initWidth()
    //   this._initScroll()
    // }, 20)

    // setTimeout(() => {
    //   this._adjust(this.activeItemId)
    // }, 500)
  },
  created(){
    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //微信浏览器
      this.isWX = true;
    }
  },
  destroyed () {
    this.$refs.navScrollWraper && this.$refs.navScrollWraper.destroy()
  },
}
</script>

<style lang="less" scoped>
@import '../../styles/mixin.less';

.navbar {
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  // top: 94px;
  left: 0;
  right: 0;
}
.navbarwx{
  top: 0;
}
.navbarapp{
  top: 94px;
}
.navBox {
  display: flex;
  flex: 1;
  // width: 100%;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
  height: 80px;
  overflow: hidden;
  box-sizing: border-box;
}

.user {
  .size(40px, 40px);
  background: url('../../assets/user.png') no-repeat center center;
  background-size: 40px 40px;
}

.info {
  .size(40px, 40px);
  background: url('../../assets/tips.png') no-repeat center center;
  background-size: 40px 40px;
  position: relative;

  .bage {
    position: absolute;
    right: -12px;
    top: -6px;
    .size(24px, 24px);
    border-radius: 50%;
    background: @brand-red;
    .text-center;
    line-height: 24px;
    .color(@white);
  }
}

.navScrollBox {
  // width: 600px;
  flex: 1;
  height: 100%;
  margin: 0 5px;
  // padding: 0 18px;
  position: relative;
  overflow: hidden;
}

.navList {
  width: 100%;
  height: 80px;
  align-items: center;
  font-family: 'PingFangSC-Regular';
  font-size: 32px;
  font-weight: 400;
  line-height: 80px;
  color: #999999;

  // overflow-x: scroll;
  .navItem {
    float: left;
    width: 100%/3;
    height: 100%;
    .text-center;

    .itemContent {
      display: inline-block;
      height: 80px;
      line-height: 78px;
      box-sizing: border-box;
    }
  }
}

.active {
  font-size: 36px;
  font-weight: 500;
  line-height: 80px;
  color: @brand-green;
  border-bottom: 4px solid @brand-green;
  font-family: 'PingFangSC-Medium';
}
</style>