<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { getStore, removeStore } from "./lib/storage";
import wechat from "./lib/wechat";
// 修改项目根目录src下的 App.vue 文件
// App.vue

export default {
  name: "App",
  data() {
    return {};
  },
  watch: {
    "$store.state.pos"() {
      //   this.geocodeLatLng();
    },
  },
  created() {
    this.addEvent();
    // this.wxlocatiion();
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
  },
  methods: {
    ...mapMutations(["CLEAR_STARTADDRESS", "CLEAR_POS", "GET_USER_LOCATION"]),
    ...mapActions(["geocodeLatLng"]),
    wxlocatiion() {
      console.log("app_wxlocatiion");
      let url = window.location.href.split("#")[0];
      wechat.wxRegister(url, this.wxRegCallback);
    },
    wxRegCallback() {
      this.wxgetlocation();
    },
    wxgetlocation() {
      console.log("APP_wxgetlocation");
      let option = {
        type: "gcj02",
        altitude: true,
      };
      wechat.getLocation(option);
    },

    addEvent() {
      window.addEventListener("beforeunload", (e) =>
        this.beforeunloadHandler(e)
      );
      window.addEventListener("unload", (e) => this.unloadHandler(e));
    },
    beforeunloadHandler(e) {
      //
      let ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        // localStorage.removeItem("Userinfos");
        this.CLEAR_STARTADDRESS();
        this.CLEAR_POS();
        if (getStore("endAddress")) {
          removeStore("endAddress");
        }
        if (getStore("endAddress")) {
          removeStore("endAddress");
        }
        if (getStore("endGeo")) {
          removeStore("endGeo");
        }
        if (getStore("endGeo")) {
          removeStore("endGeo");
        }
        if (getStore("pos")) {
          removeStore("pos");
        }
        if (getStore("pos")) {
          removeStore("pos");
        }

        if (getStore("startAddress")) {
          removeStore("startAddress");
        }
        if (getStore("startAddress")) {
          removeStore("startAddress");
        }
        if (getStore("startGeo")) {
          removeStore("startGeo");
        }
        if (getStore("startGeo")) {
          removeStore("startGeo");
        }
      }
    },
  },

  // 页面关闭之前，触发提示框
  beforeunloadHandler(e) {
    if (e) {
      e = e || window.event;
      console.log(e);
      //   if (e) {
      //     e.returnValue = "关闭提示";
      //   }
      //   return "关闭提示";
    }
  },

  destroyed() {
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
  },
};
</script>


<style lang="less">
@import "./styles/common.less";
</style>
