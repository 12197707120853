import store from "../../store/store.js";
import {
    IMappkey
} from "@/config";
import {
    getStore,
    setStore
} from "@/lib/storage.js";
export default class Chat {
    constructor() {
        this.jim = null
    }
    // JMessage连接
    getJim() {
        if (!this.jim) {
            this.jim = new JMessage({
                debug: false
            });;
            window.jim = this.jim;
        }
        console.log("getjim:", this.jim)
    }
    getRandom_str() {
        const words = 'qwertyuiopasdfghjkllzxcvbnm123456789'
        let str = ''
        for (let i = 0; i < 36; i++) {
            str += words.charAt(Math.random() * words.length - 1)
        }
        return str
    }
    /* 
     *	获取初始化需要的参数(生产环境签名的生成需要在开发者服务端生成，不然存在 masterSecret 暴露的风险)
     * 	appkey和signature换成自己的
     */
    getConfig() {
        // console.log('md5:',md5)
        // hash = hex_md5("input string");
        // md5(`appkey={appkey}&timestamp={timestamp}&random_str={random_str}&key={secret}}`)
        console.log("imjs.:", IMappkey)
        const appkey = IMappkey;
        const random_str = this.getRandom_str();
        const timestamp = Date.parse(new Date());
        console.log('random_str:', random_str)
        const signature = window.md5(
            `appkey=${appkey}&timestamp=${timestamp}&random_str=${random_str}&key=5be531814f2a5f7751aae00c`)
        return {
            appkey,
            random_str,
            timestamp,
            signature,
            flag: 1
        }
    }
    // 初始化
    init() {
        let that = this
        let Jim = this.jim
        return new Promise((resolve, reject) => {
            // 轮询保证在初始化之前im已连接
            let num = 0
            let interval = setInterval(() => {
                console.log('jimmmm:', Jim)
                num++
                if (Jim.isConnect()) {
                    clearInterval(interval)
                    console.log('连接总共消耗了' + num * 500 / 1000 + '秒');
                    // store.commit('SET_MSGNUMS', 0)
                    if (!Jim.isInit()) {
                        const config = that.getConfig()
                        console.log("config:", config)
                        Jim.init({
                            ...config,
                            flag: 1
                        }).onSuccess(function (data) {
                            console.log('<<<----------Jim初始化成功---------->>>');
                            resolve(data)
                        }).onFail(function (data) {
                            console.log('<<<----------Jim初始化失败---------->>>');
                            reject(data)
                        }).onTimeout(function (data) {
                            console.log('<<<----------Jim初始化超时---------->>>');
                            reject(data)
                        })
                    }
                }
            }, 500)
        })
    }
    // TODO 显示参数不合法:长度大于四位
    register({
        username,
        password
    }) {
        return new Promise((resolve, reject) => {
            this.jim.register({
                username,
                password
            }).onSuccess(function (data) {
                resolve(data)

            }).onFail(function (data) {
                reject(data)
            });
        })
    }
    // 判断是否登录
    isLogin() {
        return this.jim.isLogin();
    }
    // 登录
    login({
        username,
        password
    }) {
        let that = this;
        let Jim = this.jim;

        return new Promise((resolve, reject) => {
            if (!Jim.isLogin()) {
                Jim.login({
                    username,
                    password,
                }).onSuccess(function (data) {
                    // console.log("username:", username)
                    // console.log('password:', password)
                    console.log('<<<----------Jim登录成功---------->>>');
                    resolve(data)
                    Jim.onDisconnect(() => {
                        that.login({
                            username,
                            password
                        })
                    })
                    // Jim.onSyncConversation(function(dataMSG){
                    // 	console.log('onSyncConversation---:', dataMSG)
                    // })
                    Jim.onMsgReceive(function (dataMSG) {
                        // 监听消息
                        // resolve(data)]
                        // console.log('onmsgreceie---:', dataMSG)
                        let msgnums = Number(getStore("msgnums")) + 1;
                        store.commit('SET_MSGNUMS', msgnums)
                        let arr = [];
                        for (let i in dataMSG.messages) {
                            let obj = {
                                msg_id: dataMSG.messages[i].msg_id,
                                you_name: dataMSG.messages[i].content.from_name,
                                you_appkey: dataMSG.messages[i].content.from_appkey,
                                me_username: dataMSG.messages[i].content.target_username,
                                me_appkey: dataMSG.messages[i].content.target_appkey,
                                create_time: dataMSG.messages[i].ctime_ms,
                                text: dataMSG.messages[i].content.msg_body.text,
                                type: 1,
                                direction: 1 //为2表示主人发出的消息，1表示联系人
                            }
                            arr.push(obj)
                        }
                        // console.log('dataMSG:', dataMSG)
                        // console.log('im:', arr)
                        setTimeout(() => {
                            store.commit('SET_MSG_LIST', arr)
                        }, 400);

                        // localStorage.setItem('msglist', JSON.stringify(dataMSG))
                        // console.log("1msg_receive:" + data);
                    });
                }).onFail(function (data) {
                    console.log('<<<----------Jim登录失败---------->>>');
                    reject(data)
                })
            } else {
                console.log('<<<----------Jim已经登录了---------->>>');
                resolve('')
            }
        })
    }
    // 发送消息
    // 	arget_username	接收消息者 username
    // content		消息文本
    // appkey	FALSE	跨应用查询时必填，目标应用的 appkey
    sendSingleMsg({
        target_username,
        content,
        appkey
    }) {
        let that = this
        let Jim = this.jim
        return new Promise((resolve, reject) => {
            Jim.sendSingleMsg({
                target_username,
                content,
                appkey
            }).onSuccess(function (data) {
                console.log("发送消息成功", data)
                resolve(data)
            }).onFail(function (data) {
                console.log('发送消息失败', data)
            })
        })
    }

    // 退出
    loginout() {
        this.jim.loginOut();
        window.localStorage.removeItem('msglist');
    }
}