import wx from 'weixin-js-sdk';
import { wxsignature } from '@/service/';
import { APPID } from '@/config';
import { setStore } from './storage';
import store from '@/store/store'
import global from '@/store/modules/global'
export default {
    wxRegister(url, callback) {
        if (!url) {
            return
        }
        // 微信api注册
        wxsignature(url)
            .then(res => {
                console.log("wxRegister_res:", res);
                let _ = JSON.parse()
                wx.config({
                    debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: APPID, // 必填，公众号的唯一标识
                    timestamp: res.data.time, // 必填，生成签名的时间戳
                    nonceStr: res.data.nonce_str, // 必填，生成签名的随机串
                    signature: res.data.signature, // 必填，签名
                    jsApiList: [
                        'updateAppMessageShareData',
                        'updateTimelineShareData',
                        "openLocation", "getLocation"
                    ], // 必填，需要使用的JS接口列表
                });
            })
            .catch(err => {
                console.log(err);
            });
        wx.ready(() => {
            // 如果需要定制ready回调方法
            this.getLocation();
            if (callback) {
                // callback();

            }
        });
    },
    // 分享到朋友
    ShareAppMessage(option) {
        // alert(option);
        wx.updateAppMessageShareData({
            title: option.title, // 分享标题
            desc: option.desc, // 分享描述
            link: option.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: option.imgUrl, // 分享图标
            success: function () {
                // 设置成功
                // console.log('设置分享到朋友成功');
            },
        });
    },
    // 分享朋友圈
    ShareTimeline(option) {
        wx.updateTimelineShareData({
            title: option.title, // 分享标题
            link: option.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: option.imgUrl, // 分享图标
            success: function () {
                // 设置成功
                // console.log('设置分享朋友圈成功');
            },
        });
    },
    // 微信获取位置信息
    getLocation(option) {
        console.log("wx_getLocation", option)
        wx.getLocation({
            type: option, // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: function (res) {
                console.log("wechat_getLocation:", res)
                let lat = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                let lng = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                // let speed = res.speed; // 速度，以米/每秒计
                // let accuracy = res.accuracy; // 位置精度
                let pos = { lat, lng }
                setStore('pos', pos)
                store.dispatch('geocodeLatLng');
            },
            fail: function (error) {
                console.log("wechat_getlocation_err:".error)
                store.dispatch('geolocation');
            },
        });
    },
};
