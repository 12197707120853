/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
    if (!name) return;
    if (typeof content !== "string") {
        content = JSON.stringify(content);
    }
    // console.log('(name, content):',name, content);
    if (name == "startAddress" || name == "startGeo" || name == "endGeo" || name == "endAddress") {
        window.sessionStorage.setItem(name, content);
    } else {
        window.localStorage.setItem(name, content);
    }
};

/**
 * 获取localStorage
 */
export const getStore = name => {
    if (!name) return;
    if (name == "startAddress" || name == "startGeo" || name == "endGeo" || name == "endAddress") {
        return window.sessionStorage.getItem(name);
    } else {
        return window.localStorage.getItem(name);
    }
};

/**
 * 删除localStorage
 */
export const removeStore = name => {
    if (!name) return;
    if (name == "startAddress" || name == "startGeo" || name == "endGeo" || name == "endAddress") {
        window.sessionStorage.removeItem(name);
    } else {
        window.localStorage.removeItem(name);
    }
};

/**
 * 清空localStorage
 */
export const clearStore = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
};

/**
 * 退出登录清楚缓存
 */
export const clearLoginStore = () => {
    window.localStorage.removeItem("countryCodeList");
    window.localStorage.removeItem("countryShortName");
    window.localStorage.removeItem("state");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("userInfo");
    window.sessionStorage.clear();
};



