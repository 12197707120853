import {
    ADD_TRANSFER_LOCATION,
    DEL_TRANSFER_LOCATION,
    DEL_TRANSFER_LOCATION_ALL
} from '../mutation-types';
import {
    setStore
} from "../../lib/storage";
import {
    Toast
} from "vant";
const state = {
    startLocation: null,
    transferLocation: [],
    endLocation: null,
};

const mutations = {
    [ADD_TRANSFER_LOCATION](state, transferAddress) {
        // state.transferLocation.push(transferAddress.description);
        // if (!state.transferLocation.includes(transferAddress.description)) {
        //     let index = localStorage.getItem('indexWhich');
        //     state.transferLocation[index] = {
        //         address:transferAddress.description,
        //         location:latlng
        //     };
        //     setStore("transferAddress", transferAddress.description);
        // } else {
        //     Toast("您已选择过此经停点");
        // }
        // console.log('paths:',transferLocation)
        let result = state.transferLocation.some(item => {
            // console.log(item.city, transferAddress.city)
            if (item.city == transferAddress.city) {
                return true
            }
        })
        if (result) { // 如果存在
            Toast("您已选择过此经停点");
        } else {
            let index = localStorage.getItem('indexWhich');
            state.transferLocation[index] = {
                city: transferAddress.city,
                geo: transferAddress.geo
            };
            setStore("transferAddress",state.transferLocation);
        }
    },
    [DEL_TRANSFER_LOCATION](state, index) {
        state.transferLocation.splice(index, 1);
    },
    [DEL_TRANSFER_LOCATION_ALL](state){
        state.transferLocation=[]
    }
};

const actions = {
    async addTransfer({
        commit
    }) {
        commit(ADD_TRANSFER_LOCATION);
    },
    async delTransfer({
        commit
    }) {
        commit(DEL_TRANSFER_LOCATION);
    },
};

export default {
    state,
    mutations,
    actions,
};