import axios from "axios";
// import { removeStore } from '@/lib/js/storage';
import config from "./httpConfig";
import {
    Toast
} from "vant";
import QS from "qs";
import {
    getStore,
    setStore
} from "../lib/storage";
import md5 from "js-md5";
import router from "../router";

// import {
//     login,
// } from '../service';
// import {
//     SET_USER_INFO2,
// } from '../store/mutation-types';

// const state = {
//     userInfo: null
// }
// const mutations = {
//     [SET_USER_INFO2](state, userInfo) {
//         state.userInfo = userInfo;
//     }
// }
// const actions = {
//     async getUserInfo({ commit }, params) {
//         try {
//             let res = await login(...params);
//             if (res) {
//                 // console.log(res);
//                 commit(SET_USER_INFO2, res.data);
//                 localStorage.setItem('userInfo', JSON.stringify(res.data));
//             }
//         } catch (error) {
//             // console.log(error);
//         }
//     }
// }

export default function $axios(options) {
    // console.log('options:', options)
    let _baseURL = config.baseURL;
    // let _baseURL = config.baseURL3;  // 测试
    // if (options.url.includes('m/huanxiong_chat')) {
    //     _baseURL = config.baseURL2;
    // }
    // if (options.url.includes('PartnerMainMore/UpdateOpenid')) {
    //     _baseURL = config.baseURL3;
    // }
    // if (options.url.includes('UserMainMore/GetUserMain')) {
    //     _baseURL = config.baseURL3;
    // }
    // if (options.url.includes('DicGradeMore/WxJsapiqm')) {
    //     _baseURL = config.baseURL4;
    // }
    // console.log("_baseURL:", _baseURL)
    return new Promise((resolve, reject) => {
        const instance = axios.create({
            baseURL: _baseURL,
            // headers: config.headers,
            // timeout: config.timeout,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            },
            transformRequest: [
                function (data) {
                    // console.log(QS.stringify(data));
                    return QS.stringify(data);
                }
            ]
        });

        // let loadingInstance;
        // http request 拦截器
        instance.interceptors.request.use(

            config => {
                if (JSON.parse(getStore("userInfo"))) {
                    // console.log(JSON.parse(getStore("userInfo")))
                } else {
                    setStore('userInfo', JSON.parse(getStore("userInfo")))
                }
                let token = getStore("token");
                let id =
                    JSON.parse(getStore("userInfo")) &&
                    JSON.parse(getStore("userInfo")).ID
                // let token = '8CF733600696E03D';
                let min = Math.floor(Date.now() / 1000);
                if (token) {
                    config.headers.Authtion = md5(token + min)
                        .substring(8, 24)
                        .toUpperCase();
                    config.headers.random = `1${id}`;
                    config.headers.Handsome = min;
                    // config.headers.Authorization="BasicAuth D49D64D153A9DC80 2576"
                    config.headers.Authorization = "BasicAuth" + " " + token + " " + id;
                } else {
                    if (options.url.includes('PartnerMainMore/UpdateOpenid')) {
                        // _baseURL = config.baseURL3;
                    } else {
                        const _aurl = window.location.href, _alogin = "/login";
                        if (_aurl.search(_alogin) == -1) {
                            router.replace("/login");
                        }
                    }
                }
                return config;
            },
            err => {
                // Toast.clear();
                Toast("加载超时");
                return Promise.reject(err);
            }
        );

        // http response 拦截器
        instance.interceptors.response.use(
            response => {
                // console.log('response:',response)
                // loadingInstance.clear();
                // if (options.url.includes('m/huanxiong_chat')) {
                // console.log('response111111:',response)
                // }
                if (response.data === undefined) {
                    response = response.request.responseText;
                } else {
                    response = response.data;
                }


                if (response.code == 0) {
                    response.code = 1111;
                    response.msg = response.msg;
                    response.data = 10000;
                }
                // console.log("response:",response)
                if (response.code == 1 || response.code == 102 || response.code == 1111 || response.status == 200 || response.status == "success") {
                    return response;
                } else if (response.code == 401) {
                    Toast("请重新登录");
                    localStorage.clear();
                    router.replace("/login");
                } else {
                    // Toast(response.msg);/
                    if (response.msg) {
                        Toast({
                            duration: 4000, // 持续展示 toast
                            message: response.msg
                        })
                    }

                    return response;
                }
            },
            err => {
                Toast.clear();
                // Toast('加载失败');
                return Promise.reject(err);
            }
        );

        instance(options)
            .then(res => {
                // console.log('http_res:', options, res)
                if (res && res.code) {
                    resolve(res);
                } else if (res.status == "success") {
                    resolve(res);
                } else {
                    return false;
                }
            })
            .catch(error => {
                reject(error);
            });
    })

}

export function $upload(options) {
    return new Promise((resolve, reject) => {
        let instance = axios.create({
            baseURL: config.baseURL,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        instance.interceptors.request.use(
            config => {
                if (JSON.parse(getStore("userInfo"))) {
                    // console.log(JSON.parse(getStore("userInfo")))
                } else {
                    setStore('userInfo', JSON.parse(getStore("userInfo")))
                }
                let token = getStore("token");
                let id =
                    JSON.parse(getStore("userInfo")) &&
                    JSON.parse(getStore("userInfo")).ID
                // let token = '8CF733600696E03D';
                let min = Math.floor(Date.now() / 1000);
                if (token) {
                    config.headers.Authtion = md5(token + min)
                        .substring(8, 24)
                        .toUpperCase();
                    config.headers.random = `1${id}`;
                    config.headers.Handsome = min;
                    config.headers.Authorization = "BasicAuth" + " " + token + " " + id;
                }
                // console.log(config);
                return config;
            },
            err => {
                // Toast.clear();
                Toast("加载超时");
                return Promise.reject(err);
            }
        );

        // http response 拦截器
        instance.interceptors.response.use(
            response => {
                // loadingInstance.clear();
                if (response.data === undefined) {
                    response = response.request.responseText;
                } else {
                    response = response.data;
                }
                if (response.code == 1 || response.code == 102 || response.status == 200 || response.status == "success") {
                    return response;
                } else if (response.code == 401) {
                    Toast("请重新登录");
                    localStorage.clear();
                    router.replace("/login");
                } else {
                    // Toast(response.msg);

                    Toast({
                        duration: 5000, // 持续展示 toast
                        message: response.msg
                    })
                }
            },
            err => {
                Toast.clear();
                // Toast('加载失败');
                return Promise.reject(err);
            }
        );

        instance(options)
            .then(res => {
                resolve(res);
                return false;
            })
            .catch(error => {
                reject(error);
            });
    });
}