import {
    login,
    getUserAddress,
    getCouponList,
    queryUserInfo,
} from '../../service';
import {
    SET_USER_INFO,
    GET_USER_ADDRESS,
    DEL_USER_ADDRESS,
    GET_COUPON_LIST,
    SET_USER_ORDERITEM,
} from '../mutation-types';

const state = {
    // wxinfo: {
    //   openid: 'oLgwU1iZJZrgcfdOa5oWBztqKc9E',
    //   nickname: 'Turi',
    //   sex: 1,
    //   language: 'en',
    //   city: '武汉',
    //   province: '湖北',
    //   country: '中国',
    //   headimgurl:
    //     'http://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIsUCianW1JE5OeFOickSQ9O5Lqd4U1RqhU4ydy7GoQ02ntuFK2JMRqpQ8CiciarAcx8krCeHPJJRXh6w/132',
    //   privilege: [],
    // },
    userInfo: null,
    // 我的地址
    userAddress: [],
    // 优惠券
    userCouponList: [],
    // 用户订单
    orderItem: [],
};

const mutations = {
    [SET_USER_INFO](state, userInfo) {
        state.userInfo = userInfo;
    },
    [GET_USER_ADDRESS](state, userAddress) {
        state.userAddress = userAddress;
    },
    [GET_COUPON_LIST](state, userCouponList) {
        state.userCouponList = userCouponList;
    },
    [SET_USER_ORDERITEM](state, orderItem) {
        let orderlist = orderItem.map(x => {
            if (x.AfterAddress && x.AfterAddress.length) {
                // x.AfterAddress = JSON.parse(x.AfterAddress);
                try {
                    x.AfterAddress = JSON.parse(x.AfterAddress);
                } catch (err) {
                    if (x.AfterAddress.includes('"s')) {
                        x.AfterAddress = x.AfterAddress.replace('"s', "'s");
                        x.AfterAddress = JSON.parse(x.AfterAddress);
                    }
                }
            }
            return x;
        });
        // console.log('orderlist:', orderlist)
        if (orderlist.length > 0) {
            localStorage.setItem('orderId', orderlist[0].ID);
            state.orderItem = orderlist;
        } else {
            state.orderItem = [];
        }

        // commit('SET_USER_NAME_MUTATION', "John")
    },
    [DEL_USER_ADDRESS](state, index) {
        if (index < 0) {
            state.userAddress = [];
        } else {
            state.userAddress.splice(index, 1);
        }
    },
};

const actions = {
    // 获取用户信息
    async getUserInfo({
        commit
    }, params) {
        try {
            let res = await login(...params);
            if (res) {
                // console.log(res);
                commit(SET_USER_INFO, res.data);
                localStorage.setItem('userInfo', JSON.stringify(res.data));
            }
        } catch (error) {
            // console.log(error);
        }
    },
    // 获取用户地址
    async getAddress({
        commit
    }, args) {
        let res = await getUserAddress(args);
        if (res.code == 1) {
            // this.addressList = res.data;
            commit(GET_USER_ADDRESS, res.data);
        }
    },
    // 获取优惠券
    async getCoupon({
        commit
    }, args) {
        let res = await getCouponList(...args);
        if (res.code == 1) {
            let arr = res.data || [];
            commit(GET_COUPON_LIST, arr);
        }
    },
    // 老用户登录查询验证
    async queryUserAndOrder({
        commit
    }, args) {
        let res = await queryUserInfo(args);
        if (res && res.code == 1) {
            commit(SET_USER_INFO, res.data.usermain);
            commit(SET_USER_ORDERITEM, res.data.OrderItem);
            localStorage.setItem('userInfo', JSON.stringify(res.data.usermain));
        } else {
            commit(SET_USER_ORDERITEM, []);
        }
    },
};

const getters = {
    // couponList: state => {
    //   return [state.userCouponList, [], []];
    // },
};

export default {
    // namespaced: true,
    state,
    mutations,
    actions,
    getters,
};