<template>
  <div :class="isWX?'homewx':'homeapp'">
    <HeaderBar
      title="浣熊出行"
      :showLeft="false"
      v-if="!isWX"
    ></HeaderBar>
    <div class="navBar">
      <nav-bar
        :navItems="navItems"
        :activeItemId="homeActiveId"
        @tab="tab"
        @popUserInfo="popUserInfo"
      ></nav-bar>
    </div>

    <div
      v-if="noticecontent"
      class="noticeBox"
    >
      <van-notice-bar wrapable>
        <div
          slot="left-icon"
          class="leftIcon"
        >
          <van-icon name="volume-o" />
        </div>
        <div class="leftContent">{{noticecontent}}</div>
      </van-notice-bar>
    </div>
    <div>
      <div
        class="nearBy"
        v-if="orderItem.length==0"
      >附近有{{startAddress==''?'...':tripCarList==[]?0:tripCarList?tripCarList.length:'...' }}位司机候驾</div>
      <div
        class="hasOrder"
        v-if="orderItem.length>0"
        @click="goToOrder"
      >
        <span>您有订单正在进行</span>
        <i class="order-icon"></i>
      </div>

      <div class="tripLine">
        <!-- <enthesis :trip="trip"></enthesis> -->
        <div class="enthesis">
          <!-- tabs切换 -->
          <div
            class="enthesis-tabs"
            v-show="homeActiveId==2"
          >
            <div class="enthesis-items">
              <div
                class="enthesis-item-list"
                v-for="(item,index) in errandItems"
                :key="index"
                @click.stop.prevent="taberrand(index)"
                :class="{'enthesis-active':index==errandid}"
              >{{item}}</div>
            </div>
          </div>

          <!-- start address -->
          <div class="start-container">
            <div
              class="start-input"
              @click.stop.prevent="selectAddress('1')"
            >
              <input
                type="text"
                :placeholder="startPlaceholder"
                class="raccoon-input"
                ref="inputText"
                readonly
                id="oFile1"
                v-model="startAddress"
              />
            </div>
            <div
              class="recommend"
              v-if="homeActiveId==0"
            >
              <div
                class="recommend-text"
                @click.stop.prevent="selectAddress('1')"
              >为您推荐附近的最佳上车点</div>
            </div>
          </div>
          <div class="divide-line"></div>

          <!-- transfer address -->
          <div
            class="trans-container"
            v-for="(item,index) in transferLocation"
            :key="index"
          >
            <div
              class="trans-input"
              @click.stop.prevent="selectAddress('3',index)"
            >
              <input
                type="text"
                :placeholder="transferPlaceholder"
                class="raccoon-input"
                readonly
                v-model="transferLocation[index].city"
              />
            </div>
            <div
              class="del-transfer"
              @click.stop.prevent="deleteItem(index)"
            ></div>
          </div>

          <!-- end address -->
          <div class="end-container">
            <div
              class="end-input"
              @click.stop.prevent="selectAddress('2')"
            >
              <input
                type="text"
                :placeholder="endPlaceholder"
                class="raccoon-input"
                readonly
                v-model="endAddress"
              />
            </div>
            <div
              class="add-transfer"
              @click.stop.prevent="addTransferLocation"
              v-if="trip"
            ></div>
          </div>
        </div>
      </div>
      <!-- <router-view :orderItem="orderItem"></router-view> -->
      <ad :adList="adList"></ad>
    </div>
    <!-- the login form popup -->
    <login
      :showLogin="showLogin"
      @islogin="islogin"
    ></login>
    <drawer
      :showDrawer="showDrawer"
      @closeDrawer="closeDrawer"
    ></drawer>
    <!-- the coupon pop-->

    <van-popup
      v-model="showOrder"
      :overlay="true"
      :lock-scroll="true"
      class="order-pop"
    >
      <div class="order-title">进行中的订单</div>
      <div class="order-list">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            class="order-list-item"
            v-for="item in orderItem"
            :key="item.ID"
            @click="$router.push(`/wait/${item.ID}/${item.OrderType}/${item.IsAssign}/${item.State}`)"
          >
            <div class="order-type">
              <div :class="['order-type-title',item.OrderType=='1'?'order-type-trip':item.OrderType=='2'?'order-type-designated':item.OrderType=='3'?'order-type-errand':'order-type-purchase']">{{item.OrderType=='1'?'专车':item.OrderType=='2'?'代驾':item.OrderType=='3'?'取送件':'代购'}}</div>
              <div :class="[item.IsAssign=='1'?'order-type-inTimeTag':'order-type-appointTag']">{{item.IsAssign=='1'?'即时订单':'预约订单'}}</div>
              <div class="order-type-status">
                <span
                  v-if="item.State<6"
                  class="order-type-running"
                >进行中</span>
                <span v-if="item.State==6">已完成</span>
                <span v-if="item.State>6">已关闭</span>
                <i :class="[item.State<6?'order-type-icongreen':'order-type-icon']"></i>
              </div>
            </div>

            <div class="order-info">
              <div class="order-info-detail">
                <div class="order-info-detail-date">{{item.NewAppointmentTime}}</div>
                <div class="order-info-detail-start">{{item.StratAddress}}</div>
                <div
                  class="order-info-detail-start"
                  v-for="(items,inds) in item.AfterAddress"
                  :key="inds"
                >{{items.city}}</div>
                <div class="order-info-detail-end">{{item.EndAddress}}</div>
              </div>
              <div class="order-info-sum">
                <div class="order-info-sum-dollar">${{item.PayAmount-item.Discount}}</div>
                <div class="order-info-sum-cny">(¥{{Math.floor((item.PayAmount-item.Discount)*rateNum)}})</div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </van-popup>
    <!-- <oauth></oauth> -->
    <van-popup
      v-model="show3"
      position="bottom"
      :lock-scroll="true"
      :overlay="true"
      @click-overlay="closePopup"
    >
      <div style="position:absolute;top:3px;right:3px;">
        <van-icon
          name="cross"
          size="20"
          @click="closeIcon"
        />
      </div>
      <div
        class="driver-rate"
        v-for="item in pcList"
        :key="item.ID"
      >
        <div class="rated">
          <div class="rated-title">
            <div class="rated-title-txt">匿名评价</div>
          </div>
          <div class="rated-stars">
            <van-rate
              v-model="starsNum"
              :size="25"
              :count="5"
              color="#F5A623"
              void-color="#CCCCCC"
              @change="onChangeStar"
            />
          </div>
        </div>
        <div class="order-info-detail-date">{{item.NewAppointmentTime}}</div>
        <div class="order-route">
          <div class="order-address order-startAddress">{{item.StratAddress}}</div>
          <div
            class="order-address order-startAddress"
            v-for="(items,inds) in item.AfterAddress"
            :key="inds"
          >{{items.city}}</div>
          <div class="order-address order-endAddress">{{item.EndAddress}}</div>
        </div>
        <div class="driverInfoBox">
          <div class="driverInfo">
            <div class="driver-image">
              <img
                :src="imgUrl"
                alt
              />
            </div>
            <div class="driver-des-finish">
              <div class="driver-name-finish">{{item.PartnerName}}</div>
              <div class="driver-stars">
                <div class="driver-star">5.0</div>
                <div class="star-list">
                  <van-rate
                    v-model="stars1"
                    :size="15"
                    :count="5"
                    color="#F5A623"
                    void-color="#CCCCCC"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="priceAll">
            <div class="priceAmerical">${{item.OrderPay}}</div>
            <div class="priceChina">(¥{{Math.floor((item.OrderPay)*rateNum)}})</div>
          </div>
        </div>
        <div class="order-number">订单编号：{{item.OrderNumberID}}</div>
        <div v-if="starsNum>0">
          <div class="rate-pop-starTagBox">
            <div class="rate-pop-tagList">
              <div
                class="rate-pop-tagItem"
                :class="{'active':tagList.includes(tag.id)}"
                v-for="tag in tags"
                :key="tag.id"
                @click.stop.prevent="chooseTag(tag.id)"
              >{{tag.txt}}</div>
            </div>
          </div>
          <div class="rate-pop-customComment">
            <textarea
              name="customComment"
              v-model="commentContent"
              id="customComment"
              placeholder="其他想说的(只有浣熊平台可见,司机不可见)"
              :adjust-position="false"
              @blur="inputLoseFocus()"
            ></textarea>
          </div>
          <div
            class="submitRate"
            :class="{ 'active': starsNum>0 }"
            @click.stop.prevent="comment"
          >匿名提交</div>
        </div>
      </div>
    </van-popup>
    <!-- 优惠券 -->
    <!-- <van-dialog id="van-dialog" /> -->
    <div
      class="shadeBox"
      v-if="showCoupon"
      @click="getCoupon"
    ></div>
    <div v-if="showCoupon">
      <div
        class="shadeCoupon"
        v-for="item in couponList"
        :key="item.codeid"
      >
        <div class="coupontitle">恭喜您</div>
        <div class="couponmoney">获得价值${{item.maxmoney||item.price}}的优惠券</div>
        <div class="couponimg">
          <div class="couponLeft">
            <div class="couponallmoney">
              <span class="couponSymbol">$</span>
              <span class="couponNumer">{{item.maxmoney||item.price}}</span>
            </div>
            <div class="couponCondition">满${{item.orderPrice||item.maxprice}}可用</div>
          </div>
          <div class="couponRight">
            <div class="couponName">{{item.name}}</div>
            <div class="couponTime">{{startime}}-{{endtime}}</div>
            <div class="couponReason">获得原因:{{item.name}}</div>
          </div>
        </div>
        <div
          class="couponButton"
          @click="getCoupon"
        >立即领取</div>
        <div class="couponClose">
          <van-icon
            name="close"
            size="30px"
            color="#fff"
            @click="getCoupon"
          />
        </div>
      </div>
    </div>
    <!-- <div @click="toIm">go to im</div> -->
  </div>
</template>
<script>
// import oauth from "../oauth/oauth";
import HeaderBar from "@/components/headerbar/headerbar";
import NavBar from "@/components/navbar/navbar";
import { Popup, List, Toast, Rate, Icon, NoticeBar, Dialog } from "vant";
import Login from "@/components/login/login";
import Drawer from "@/components/drawer/drawer";
import Ad from "@/components/ad/ad";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { getStore, removeStore } from "@/lib/storage.js";
import { CUSTOM_VERSION } from "@/config/";

import {
  getAdvertise,
  popupComment,
  updateComment,
  closeComment,
  noticeContent,
  checkCoupon,
  selectPopup,
  getversion,
  getCoupon,
} from "@/service/";
import wechat from "@/lib/wechat";
import { setStore } from "../../lib/storage";
// import md5 from "js-md5";
// import { geocode } from '@/mixins/geocodeLatLng.js'
// import google from '../lib/google-map.js'

export default {
  name: "home",
  components: {
    HeaderBar,
    NavBar,
    [Popup.name]: Popup,
    [List.name]: List,
    [Rate.name]: Rate,
    [Icon.name]: Icon,
    // [Dialog.Component.name]: Dialog.Component,
    [NoticeBar.name]: NoticeBar,
    Login,
    Drawer,
    Ad,

    // oauth: oauth
  },
  // mixins: [geocode],
  data() {
    return {
      registerNum: 0,
      isWX: false,
      version: CUSTOM_VERSION,
      trip: true,
      navItems: [
        {
          name: "出行",
          path: "trip",
          // components: Trip,
          id: 0,
        },
        {
          name: "代驾",
          path: "designated",
          // components: Designated,
          id: 1,
        },
        {
          name: "跑腿",
          path: "errand",
          // components: Errand,
          id: 2,
        },
        // {
        //   name: '搬家',
        //   path: 'movehouse',
        //   // components: Errand,
        //   id: 3
        // },
        // {
        //   name: '专车',
        //   path: 'specialcar',
        //   // components: Errand,
        //   id: 4
        // },
      ],
      errandItems: ["取送件", "代购"],
      // homeActiveId: 0,
      // showLogin: false,
      telphone: "",
      // telPre: '+86',
      code: "",
      showSelect: false,
      show3: false,
      starsNum: 0,
      defaultPre: 0,
      endAddress: "",
      stars1: 5,
      tagList: [],
      commentContent: "",
      showCoupon: false,
      // popupTable:false,

      drawerList: [
        {
          id: 0,
          imgClass: "drawer-icon-order",
          path: "/order",
          txt: "我的订单",
        },
        {
          id: 1,
          imgClass: "drawer-icon-point",
          path: "/points",
          txt: "我的积分",
        },
        {
          id: 2,
          imgClass: "drawer-icon-coupon",
          path: "/coupon",
          txt: "优惠券",
        },
        {
          id: 3,
          imgClass: "drawer-icon-couponcode",
          path: "/exchangecoupon",
          txt: "优惠码",
        },
        {
          id: 4,
          imgClass: "drawer-icon-invite",
          path: "/invite",
          txt: "邀请好友",
        },
        {
          id: 5,
          imgClass: "drawer-icon-setting",
          path: "/setting",
          txt: "设置",
        },
      ],
      tags: [
        {
          id: 0,
          txt: "车辆肮脏凌乱",
        },
        {
          id: 1,
          txt: "开车不稳不专心",
        },
        {
          id: 2,
          txt: "车内有异味",
        },
        {
          id: 3,
          txt: "强行索要小费",
        },
        {
          id: 4,
          txt: "司机恶意骚扰",
        },
        {
          id: 5,
          txt: "车辆信息不符",
        },
      ],
      showDrawer: false,
      showLogin: false,
      startPlaceholder: "定位中...",
      transferPlaceholder: "请输入您的经停点",
      endPlaceholder: "请输入您的终点",
      showOrder: false,
      loading: false,
      finished: false,
      list: [],
      adList: [],
      pcLists: [],
      pcList: [],
      orderDetail: "",
      count2: "",
      StarCount: "",
      commentId: "",
      CState: "",
      resCode: "",
      noticecontent: "",
      couponList: "",
      counptid: "",
      endtime: "",
      startime: "",
      couponname: "",
    };
  },
  computed: {
    ...mapState({
      userInfo: ({ userVal }) => userVal.userInfo,
      countryCodeList: ({ globalVal }) => globalVal.countryCodeList,
      rateNum: ({ globalVal }) => globalVal.rateNum,
      carList: ({ globalVal }) => globalVal.carList,
      //   startAddress: ({ globalVal }) =>
      //     globalVal.startAddress || getStore("startAddress"),
      startAddress: ({ globalVal }) => globalVal.startAddress,
      transferLocation: ({ pathVal }) =>
        pathVal.transferLocation || getStore("transferAddress"),
      //   startGeo: ({ globalVal }) =>
      //     globalVal.startGeo || JSON.parse(getStore("startGeo")),
      startGeo: ({ globalVal }) => globalVal.startGeo,
      homeActiveId: ({ globalVal }) => globalVal.homeActiveId,
      orderItem: ({ userVal }) => userVal.orderItem,
      typeList: ({ globalVal }) => globalVal.typeList,
      errandid: ({ globalVal }) => globalVal.errandid,
      ...mapGetters(["tripCarList", "defaultSelectedCar"]),
      // showOrder: ({ globalVal }) => globalVal.showOrder,
      imgUrl() {
        return require(`../../assets/shareIcon.png`);
      },
    }),
    currentActiveItem() {
      return this.navItems[this.homeActiveId].components;
    },
    scrollHeight() {
      return document.documentElement.scrollTop || document.body.scrollTop || 0;
    },

    position() {
      return getStore("pos");
    },
  },
  watch: {
    startAddress() {
      let latlng = JSON.stringify(this.startGeo);
      let type = this.typeList[this.homeActiveId].typenumber;
      this.getCarListAround([latlng, type]);
    },

    activeItemId() {
      let latlng = JSON.stringify(this.startGeo);
      let type = this.typeList[this.homeActiveId].typenumber;
      this.getCarListAround([latlng, type]);
    },
    orderItem() {
      if (this.orderItem.length > 0) {
        this.showOrder = true;
      } else {
        this.showOrder = false;
        if (getStore("msglist")) removeStore("msglist");
        if (getStore("orderId")) removeStore("orderId");
        if (getStore("msgnums")) removeStore("msgnums");
        if (getStore("im_Pmid")) removeStore("im_Pmid");
        if (getStore("im_Umid")) removeStore("im_Umid");
      }
    },
  },
  methods: {
    ...mapMutations([
      "DEL_TRANSFER_LOCATION",
      "SET_ERRANDID",
      "SET_HOMEACTIVEID",
      "GET_USER_LOCATION",
      "CLEAR_STARTADDRESS",
    ]),
    ...mapActions([
      "getCountry",
      "geolocation",
      "getCarListAround",
      "geocodeLatLng",
      "exchangeRateHandler",
      "queryUserAndOrder",
      "getgoogleMapPosition",
    ]),
    ymreload() {
      this.$router.reload();
    },
    toIm() {
      this.$router.push(`/im/1`);
    },
    // 比较版本号
    versionAnswer(vNew, vOld) {
      //   console.log(vNew, vOld);
      if (vNew == "" || vNew == null || vNew == undefined) {
        return false;
      } else {
        var vn = vNew.replace(/(^\s+)|(\s+$)/gi, "");
      }
      var vo = vOld.replace(/(^\s+)|(\s+$)/gi, "");
      if (!vn || !vo) return true;

      var reg = /\d(\.|\d)*\d/gi;
      vn = vn.match(reg)[0];
      vo = vo.match(reg)[0];
      var vnArr = vn.split(".");
      var voArr = vo.split(".");
      // 版本比较 1.1.1类型
      if (vnArr[0] > voArr[0]) {
        return true;
      } else if (vnArr[0] == voArr[0]) {
        if (vnArr[1] > voArr[1]) {
          return true;
        } else if (vnArr[1] == voArr[1]) {
          if (vnArr[2] > voArr[2]) {
            return true;
          } else if (vnArr[2] == voArr[2]) {
            return false;
          }
        }
      }
      return false;
    },
    popUserInfo() {
      let userInfo = JSON.parse(getStore("userInfo"));
      // console.log(this.showLogin)
      if (userInfo && userInfo.ID) {
        this.showDrawer = true;
      } else {
        this.showLogin = true;
      }
    },
    inputLoseFocus() {
      window.scroll(0, 0);
    },
    // tab切换
    taberrand(index) {
      this.SET_ERRANDID(index);
    },
    goToOrder() {
      this.showOrder = true;
    },
    closeOrderPop() {
      console.log(this.showOrder);
      this.showOrder = false;
      this.showOrderPop = false;
    },
    onLoad() {
      setTimeout(() => {
        // 加载状态结束
        this.loading = false;
        this.finished = true;
      }, 500);
    },
    // 选择地址跳转路由
    selectAddress(address, index) {
      console.log("selectAddress", address, index);
      let type = this.navItems[this.homeActiveId].path;
      if (address == 3) {
        this.$router.push({
          path: `/searchAddress/home/${address}`,
          query: {
            indexWhich: index,
          },
        });
      } else if (address == 1 || this.startAddress == "") {
        this.$router.push(`/searchAddress/home/${address}`);
      } else {
        this.$router.push(`/searchAddress/${type}/${address}`);
      }
    },
    // 添加中间位置
    addTransferLocation() {
      console.log("transferGeo:place:", this.transferLocation);
      if (this.transferLocation.length < 5) {
        this.transferLocation.push("");
      }
    },
    // 删除中间位置
    deleteItem(index) {
      this.DEL_TRANSFER_LOCATION(index, 1);
    },
    islogin() {
      // console.log(`调用islogin`)
      this.showLogin = false;
      // console.log(this.showLogin)
    },
    changestartPlaceholder() {
      console.log("changestartPlaceholder");
      let _this = this;
      setTimeout(() => {
        console.log("change111");
        if (!_this.startAddress) {
          console.log("change222");
          _this.startPlaceholder = "请手动输入起点位置";
        }
      }, 3000);
    },
    tab(index) {
      // console.log("is-disabled", index);
      console.log("index:", index);
      this.SET_HOMEACTIVEID(index);

      //   this.geocodeLatLng();
      // console.log(`home:${index}`)
      // this.$router.push(`/${this.navItems[index].path}`)
    },
    selectPre() {
      this.showSelect = !this.showSelect;
    },
    change(index) {
      this.defaultPre = index;
      this.showSelect = false;
    },
    getCode() {
      // console.log(`get random code`)
    },
    //首页查询优惠券
    async checkCoupon() {
      this.popupComment();
      let userid = JSON.parse(getStore("userInfo")).ID;
      let res = await checkCoupon(userid);
      console.log("checkCoupon:", res);
      if (res && res.data) {
        this.couponList = [res.data];
        this.counptid = res.data.codeid;
        if (res.data.time && res.data.time.includes("T")) {
          this.startime = res.data.time.split("T")[0].replace(/-/g, ".");
        }
        if (res.data.endtime && res.data.endtime.includes("T")) {
          this.endtime = res.data.endtime.split("T")[0].replace(/-/g, ".");
        }
        if (res.data.name) {
          this.couponname = res.data.name;
          this.showCoupon = true;
        }
      }
    },
    //首页领取优惠券
    async getCoupon() {
      //  if(this.couponname=='新手注册活动'||this.couponname=='分享人活动'||this.couponname=='感谢您的再次使用'){
      this.showCoupon = false;
      this.popupComment();
      //  }else{
      let userid = JSON.parse(getStore("userInfo")).ID;
      let res = await getCoupon(userid, this.counptid);
      console.log("getCoupongetCoupongetCoupon:", res);
      if (res.code == 1) {
        this.showCoupon = false;
        Toast("领取成功");
        this.popupComment();
        // }
      }
    },
    //删除优惠券
    //  async deleteCoupon(){
    //     // if(this.couponname=='新手注册活动'||this.couponname=='分享人活动'){
    //    this.showCoupon=false
    //    this.popupComment();
    //  }else{
    //     let userid = JSON.parse(getStore("userInfo")).ID;
    //   let res = await deleteCoupon(userid,this.counptid);
    //      console.log(res)
    //      if(res.code==1){
    //      this.showCoupon=false
    //      Toast('删除成功');
    //       this.popupComment();
    //     }
    //  }
    // },

    // 查询首页新优惠券
    async selectPopup() {
      let userid = JSON.parse(getStore("userInfo")).ID;
      let res = await selectPopup(userid);
      if (res.code == 1) {
        if (res.data.popup == 1) {
          this.couponList = [res.data];
          this.startime = res.data.time.split("T")[0].replace(/-/g, ".");
          this.endtime = res.data.end.split("T")[0].replace(/-/g, ".");
          this.counptid = res.data.codeid;
          this.couponname = res.data.name;
          this.showCoupon = true;
        } else {
          this.checkCoupon();
        }
      }
    },

    onChangeStar(count) {
      this.count2 = count;
      if (this.count2 == 5) {
        this.tagList = [];
      }
    },
    chooseTag(id) {
      if (this.starsNum != 5) {
        let index = this.tagList.indexOf(id);
        if (index != -1) {
          this.tagList.splice(index, 1);
        } else {
          this.tagList.push(id);
        }
        let str = "";
        for (let i = 0; i < this.tags.length; i++) {
          for (let n = 0; n < this.tagList.length; n++) {
            if (this.tags[i].id == this.tagList[n]) {
              str += this.tags[i].txt + ",";
            }
          }
        }
        console.log(str);
        this.LabelInfo = str;
      }
    },
    //公告
    async noticeContent() {
      let Umid = JSON.parse(getStore("userInfo")).ID;
      let res = await noticeContent(3, Umid);
      if (res.data.length != 0) {
        this.noticecontent = res.data[0].Name;
      }
    },
    //弹出评论
    async popupComment() {
      let Umid = JSON.parse(getStore("userInfo")).ID;
      let res = await popupComment(Umid);
      if (res && res.code == 1) {
        this.pcLists = res.data.filter(function (item) {
          return item.State == 6;
        });
        if (this.pcLists.length != 0) {
          this.pcList = [this.pcLists[0]];
          this.orderDetail = this.pcLists[0];
          this.StarCount = this.orderDetail.StarCount;
          console.log(this.StarCount);
          this.commentId = this.orderDetail.Cid;
          this.CState = this.orderDetail.CState;
          if (this.CState == 0) {
            this.show3 = true;
          }
        }
      } else {
        //  this.show3=false
        // Toast(res.msg);
      }
    },
    comment() {
      this.postComment();
    },

    //关闭评论
    async closeComment() {
      let res = await closeComment(this.commentId);
      if (res.code == 1) {
        // Toast(res.msg)
        this.resCode = res.code;
        // console.log(this.CState)
      } else {
        // Toast(res.msg)
      }
    },
    closePopup() {
      this.show3 = false;
      this.closeComment();
      console.log(123);
    },
    closeIcon() {
      this.show3 = false;
      this.closeComment();
    },
    //提交评论
    async postComment() {
      if (this.starsNum > 0) {
        let res = await updateComment(
          this.orderDetail.Umid,
          this.orderDetail.Pmid,
          this.starsNum,
          this.orderDetail.ID || "",
          this.LabelInfo,
          this.commentContent
        );
        if (res.code == 1) {
          this.show3 = false;
          //  this.starNumber=this.starsNum
          Toast(res.msg);
        } else {
          Toast(res.msg);
        }
      }
    },
    // 获取广告
    async getBannerList() {
      let res = await getAdvertise();
      if (res.code == 1) {
        this.adList = res.data;
        // console.log("adList", res);
      } else {
        Toast(res.msg);
      }
    },
    // 获取谷歌密钥
    async getApiKeys() {
      let userid = JSON.parse(getStore("userInfo")).ID;
      let res = await ApiKeys(userid);
      console.log("getApiKeys", res);
      if (res.code == 1) {
        console.log(res.data[0]);
        localStorage.setItem("API_KEY2", res.data[0]);
      }
    },

    // 关闭抽屉
    closeDrawer() {
      this.showDrawer = false;
    },

    wxgetlocation() {
      console.log("wxgetlocation");
      let option = {
        type: "gcj02",
        altitude: true,
      };
      wechat.getLocation(option);
    },

    wxRegCallback() {
      this.wxgetlocation();
    },
    wxlocatiion() {
      console.log("wxlocatiion");
      let url = "";
      if (navigator.userAgent.indexOf("iPhone") !== -1) {
        // IOS 记录微信菜单打开时的url
        // getItemByEntryUrl()方法 是拿到之前存在storage中的URL
        // url = getItemByEntryUrl();
      } else {
        // 安卓 记录当前使用SDK的页面的url
        const { href } = window.location;
        url = href.split("#")[0];
      }
      //   let url = window.location.href.split("#")[0];

      wechat.wxRegister(url, this.wxRegCallback);
    },
  },

  created() {
    console.log("home_");
    this.getBannerList();
    this.selectPopup();
    this.noticeContent();
    // this.getApiKeys();
    let ua = window.navigator.userAgent.toLowerCase(),
      _this = this;
    // this.CLEAR_STARTADDRESS();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //微信浏览器
      this.isWX = true;
    }

    const u = navigator.userAgent;
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    console.log("navigator.geolocation：", navigator.geolocation);
    console.log("isiOS:", isiOS);

    // if (isiOS) {
    this.wxlocatiion();
    // }
    let userGeo = JSON.parse(getStore("pos"));
    console.log("created_userGeo:", userGeo);
    console.log("|this.startAddress:", this.startAddress);
    if (
      userGeo == undefined ||
      userGeo == null ||
      this.startAddress == "" ||
      this.startAddress == null
    ) {
      console.log("created_getgoogleMapPosition");
      this.CLEAR_STARTADDRESS();
      //   this.startAddress = "";
      this.$nextTick(function () {
        // this.startAddress = "";
        document.getElementById("oFile1").value = "";
      });
      this.getgoogleMapPosition();
      this.changestartPlaceholder();
    }
  },

  async mounted() {
    console.log("home_mounted");
    removeStore("annidx");

    // this.geocodeLatLng();

    if (!this.countryCodeList) {
      this.getCountry();
    }
    if (!this.rateNum) {
      this.exchangeRateHandler();
    }

    let userInfo = await JSON.parse(getStore("userInfo"));
    if (userInfo && userInfo.ID) {
      this.queryUserAndOrder(userInfo.ID);
      this.showLogin = false;
    } else {
      this.showLogin = true;
    }
    // this.$router.push(`/trip`)
    // this.$router.push(`${this.navItems[this.homeActiveId].path}`)

    if (this.startAddress) {
      let latlng = JSON.stringify(this.startGeo);
      let type = this.typeList[0].typenumber;
      this.getCarListAround([latlng, type]);
    }
    let ua = window.navigator.userAgent.toLowerCase();

    if (ua.match(/MicroMessenger/i) != "micromessenger" && this.version) {
      let res = await getversion();
      //   console.log(res.data.RaccoonEditionUserAndroid, this.version);
      if (
        this.versionAnswer(res.data.RaccoonEditionUserAndroid, this.version)
      ) {
        Dialog.alert({
          message: "发现的的版本，请升级版本",
        }).then(() => {
          setStore("APP_Update", 1);
          plus.runtime.openURL("http://down.raccoontrip.com/index.html"); //调用浏览器打开网址
        });
      } else if (getStore("APP_Update") == 1) {
        removeStore("APP_Update");
      }
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";
@import "../../styles/orderpop.less";
@import "../../styles/form.less";

.nearBy {
  margin: 20px auto;
  .fontSize(24px);
  line-height: 34px;
  .text-center;
  .color(@gray);
}

.hasOrder {
  width: 300px;
  height: 60px;
  margin: 20px auto;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  text-align: center;
  line-height: 60px;
  font-size: 24px;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  color: rgba(12, 170, 50, 1);

  .order-icon {
    display: inline-block;
    margin-left: 10px;
    .size(10px, 20px);
    background: url("../../assets/back_green.png") 0 0 no-repeat;
    background-size: 10px 20px;
  }
}

.tripLine {
  margin-bottom: 10px;
}

.drawer {
  overflow: hidden;
  height: 100%;

  &-box {
    // position: relative;
    width: 650px;
    height: 100%;
    // background: rgba(255, 255, 255, 1);
    background: rgba(255, 255, 255, 1) url("../../assets/Path 2@2x.png") 0
      bottom no-repeat;
    background-size: 650px 322px;
    overflow: hidden;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    .size(650px, 464px);
    background: url("../../assets/drawer_header.png") 0 0 no-repeat;
    background-size: 650px 464px;
  }

  &-username {
    margin-bottom: 14px;
    font-size: 50px;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 72px;
  }

  &-usertel {
    font-size: 30px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 42px;
  }

  &-icon {
    &-right {
      .size(10px, 20px);
      margin-left: 14px;
      background: url("../../assets/arrow_right.png") 0 0 no-repeat;
      background-size: 10px 20px;
    }

    &-order {
      background: url("../../assets/drawer_order.png") center center no-repeat;
      background-size: 32px 40px;
    }

    &-point {
      background: url("../../assets/drawer_score.png") center center no-repeat;
      background-size: 40px 40px;
    }

    &-coupon {
      background: url("../../assets/drawer_coupon.png") center center no-repeat;
      background-size: 40px 30px;
    }

    &-couponcode {
      background: url("../../assets/drawer_couponcode.png") center center
        no-repeat;
      background-size: 40px 30px;
    }

    &-invite {
      background: url("../../assets/drawer_invite.png") center center no-repeat;
      background-size: 40px 32px;
    }

    &-setting {
      background: url("../../assets/drawer_setting.png") center center no-repeat;
      background-size: 40px 40px;
    }
  }

  &-list {
    margin: 64px auto 32px;

    &-item {
      display: flex;
      flex: 1;
      padding-left: 60px;
      margin-bottom: 40px;

      &-icon {
        .size(42px, 42px);
        // display: flex;
        // flex: 1;
        // align-items: center;
        // justify-content: center;
        margin-right: 30px;
      }

      &-title {
        font-size: 30px;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 42px;
      }
    }
  }

  &-code {
    .size(108px, 108px);
    // margin: 52px auto;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  &-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    .size(200px, 200px);
    bottom: 110px;
    left: 220px;
    border-radius: 50%;
    border: 10px solid #fff;
    background: #f8f8f8;
    box-sizing: border-box;
  }
}

.enthesis {
  .size(690px, auto);
  margin: 0 auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  box-sizing: border-box;
}

.start-container {
  // width: 100%;
  // height: 132px;
  padding: 33px 36px 25px 21px;
  // padding: 48px 0 40px;
  // display: flex;
  // flex: 1;
  // align-items: center;
  box-sizing: border-box;
}

.start-input {
  // width: 100%;
  // height: 100%;
  // display: flex;
  align-items: center;
  // padding: 0 36px 0 70px;
  padding-left: 49px;
  background: url("../../assets/start@2x.png") 0 center no-repeat;
  background-size: 28px 28px;

  box-sizing: border-box;

  input {
    width: 100%;
    font-size: 32px;
    font-family: "PingFang SC";
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 44px;
    background: rgba(255, 255, 255, 1);
  }
}

.recommend {
  padding-left: 49px;
  height: 34px;
  font-size: 24px;
  font-family: "PingFang SC";
  font-weight: 300;
  color: rgba(12, 170, 50, 1);
  line-height: 34px;
}

.divide-line {
  width: 580px;
  margin-left: 74px;
  height: 2px;
  background: rgba(238, 238, 238, 1);
}

.raccoon-input {
  height: 44px;
  .fontSize(32px);
  line-height: 44px;
}

.end-container {
  // padding: 0 36px 0 70px;
  // background: url("../../assets/end@2x.png") 21px center no-repeat;
  // background-size: 28px 28px;
  // display: flex;
  // flex: 1;
  // align-items: center;
  // box-sizing: border-box;
  box-sizing: border-box;
  padding: 38px 36px 30px 21px;
}

.end-input {
  align-items: center;
  // padding: 0 36px 0 70px;
  padding-left: 49px;
  background: url("../../assets/end@2x.png") 0 center no-repeat;
  background-size: 28px 28px;

  box-sizing: border-box;
  input {
    width: 100%;
    font-size: 32px;
    font-family: "PingFang SC";
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 44px;
    background: rgba(255, 255, 255, 1);
  }
}

.add-transfer {
  .size(32px, 32px);
  background: url("../../assets/add@2x.png") right center no-repeat;
  background-size: 32px 32px;
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #ccc;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #ccc;
  opacity: 1;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ccc;
  opacity: 1;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #ccc;
}
.driver-rate {
  // padding: 0 20px;
  .rate-title {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    font-size: 30px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 42px;
  }
  .rated {
    width: 690px;
    height: 200px;
    margin: 30px auto;
    padding: 30px 30px 40px;
    background: rgba(255, 255, 255, 1);
    // box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    box-sizing: border-box;

    .rated-title {
      display: flex;
      flex: 1;
      align-items: center;
      margin-bottom: 40px;
      .rated-title-txt {
        font-weight: 600;
        text-align: center;
        margin-left: 245px;
        margin-top: -30px;
      }
      &-txt {
        margin-right: 20px;
        font-size: 36px;
        font-family: "PingFangSC-Medium";
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 50px;
      }
    }
    .rated-stars {
      text-align: center;
    }
  }
}
.order-address {
  // height: 40px;

  padding-left: 48px;
  margin-bottom: 10px;
  font-size: 28px;
  font-family: "PingFangSC-Light";
  font-weight: 300;
  color: rgba(34, 34, 34, 1);
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.order-startAddress {
  margin-left: 20px;
  background: url("../../assets/start@2x.png") 0 6px no-repeat;
  background-size: 28px 28px;
}
.order-endAddress {
  margin-left: 20px;
  background: url("../../assets/end@2x.png") 0 6px no-repeat;
  background-size: 28px 28px;
}
.order-number {
  height: 34px;
  margin-top: 30px;
  margin-bottom: 40px;
  padding-left: 20px;
  font-size: 24px;
  font-family: "PingFangSC-Light";
  font-weight: 300;
  color: rgba(153, 153, 153, 1);
  line-height: 34px;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  padding-bottom: 20px;
}
.order-info-detail-date {
  margin-left: 20px;
  margin-top: -50px;
  padding-left: 42px;
  margin-bottom: 10px;
  background: url("../../assets/grey-clock@2x.png") 0 center no-repeat;
  background-size: 28px 28px;
  font-size: 28px;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 40px;
}
.driverInfoBox {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(238, 238, 238, 1);
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  .priceAll {
    height: 70px;
    line-height: 50px;
  }
  .priceAmerical {
    // margin-right:10px;
    padding-top: 10px;
    font-size: 30px;
    color: rgba(51, 51, 51, 1);
    text-align: center;
  }
  .priceChina {
    margin-right: 20px;
    color: #ccc;
    font-size: 30px;
    font-weight: 600;
  }
}
.driverInfo {
  display: flex;
  // flex: 1;
  padding: 20px 0 !important;
  align-items: center;

  .driver {
    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      .size(80px, 80px);
      margin-right: 10px;
      border-radius: 50%;
      overflow: hidden;
      .bg(#fcfcfc);
      img {
        width: 50px;
      }
    }
    &-des {
      margin-left: 20px;
      margin-right: auto;
      &-finish {
        // display: flex;
        // padding:0!important;
      }
    }
    &-name {
      font-size: 30px;
      font-family: "PingFangSC-Medium";
      font-weight: 500;
      line-height: 42px;
      &-finish {
        font-size: 30px;
        font-family: "PingFangSC-Medium";
        font-weight: 500;
        line-height: 42px;
        margin-right: 20px;
      }
    }
    &-stars {
      display: flex;
      flex: 1;
      height: 34px;
      margin-top: 4px;
      align-items: center;
      font-size: 24px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(245, 166, 35, 1);
      line-height: 34px;
    }
    &-tel {
      .size(80px, 80px);
      background: url("../../assets/tel@2x.png") 0 0 no-repeat;
      background-size: 80px 80px;
    }
  }
}
.star-list {
  padding-top: 3px;
  padding-left: 5px;
}
.rate-pop {
  position: relative;
  //   height: 984px;
  padding-top: 60px;
  box-sizing: border-box;

  &-close {
    .size(30px, 30px);
    position: absolute;
    right: 30px;
    top: 30px;
    background: url("../../assets/delete@2x.png") 0 0 no-repeat;
    background-size: 30px 30px;
  }

  &-title {
    box-sizing: border-box;
    margin-bottom: 40px;
    font-size: 36px;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    color: rgba(34, 34, 34, 1);
    line-height: 50px;
    text-align: center;
  }

  &-stars {
    text-align: center;
  }

  &-starcontent {
    margin: 30px auto 40px;
    font-size: 24px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(245, 166, 35, 1);
    line-height: 34px;
    text-align: center;
  }

  &-starTagBox {
    width: 590px;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
  }

  &-tagList {
    height: 70px;
    margin-right: -30px;

    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }

  &-tagItem {
    width: 280px;
    height: 70px;
    float: left;
    margin: 0 30px 20px 0;
    border-radius: 4px;
    border: 1px solid rgba(204, 204, 204, 1);
    font-size: 24px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 70px;
    text-align: center;
    box-sizing: border-box;
    &.active {
      background: #f5a623;
      border-radius: 2px;
      border: 1px solid #f5a623;
      color: #fff;
    }
  }

  &-customComment {
    width: 590px;
    height: 160px;
    margin: 0 auto;
    background: rgba(248, 248, 248, 1);
    border-radius: 4px;

    textarea {
      display: block;
      .size(100%, 100%);
      padding: 30px;
      box-sizing: border-box;
      border: none;
      background: rgba(248, 248, 248, 1);
      font-size: 24px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(204, 204, 204, 1);
      line-height: 34px;

      &::placeholder {
        font-size: 24px;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: rgba(204, 204, 204, 1);
        line-height: 34px;
      }
    }
  }
}
.order {
  &-cancel {
    &-pop {
      width: 690px;
      height: 886px;
      background: rgba(255, 255, 255, 1);
      border-radius: 20px;
      box-sizing: border-box;
    }

    &-title {
      margin-top: 60px;
      margin-bottom: 40px;
      font-size: 36px;
      font-family: "PingFangSC-Medium";
      font-weight: 500;
      color: rgba(34, 34, 34, 1);
      line-height: 50px;
      text-align: center;
    }

    &-container {
      padding: 0 50px;
    }

    &-tags {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    &-tagItem {
      width: 280px;
      height: 80px;
      box-sizing: border-box;
      margin-bottom: 30px;
      border-radius: 4px;
      border: 2px solid rgba(204, 204, 204, 1);
      font-size: 28px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 80px;
      text-align: center;
    }

    &-tagItemActive {
      margin-bottom: 30px;
      width: 280px;
      height: 80px;
      background: rgba(12, 170, 50, 0.1);
      border-radius: 4px;
      font-size: 28px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(12, 170, 50, 1);
      line-height: 80px;
      text-align: center;
    }

    &-customTxt {
      margin-bottom: 40px;
      height: 254px;
      // padding: 30px;
      background: rgba(248, 248, 248, 1);
      border-radius: 4px;
    }

    &-txtarea {
      width: 100%;
      height: 100%;
      padding: 30px;
      border: none;
      background: rgba(248, 248, 248, 1);
      box-sizing: border-box;
      font-size: 28px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
    }

    &-confirBtn {
      height: 110px;
      border-top: 2px solid rgba(238, 238, 238, 1);
      font-size: 36px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(12, 170, 50, 1);
      line-height: 110px;
      text-align: center;
    }
  }
}
.submitRate {
  width: 590px;
  height: 80px;
  margin: 20px auto;
  background: rgba(204, 204, 204, 1);
  border-radius: 4px;
  font-size: 30px;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 80px;
  text-align: center;
  &.active {
    background-color: #333;
  }
}
.rate-pop-tagItem {
  border: 2px solid #ccc !important;
}
//优惠券
.shadeBox {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  opacity: 0.4;
  top: 0;
  left: 0;
}

.shadeCoupon {
  box-sizing: border-box;
  background: url("../../assets/couponticket.png") 0 0 no-repeat;
  background-size: 520px 527px;
  width: 520px;
  height: 527px;
  //   position: relative;
  //   top: -150px;
  position: fixed;
  top: 300px;
  left: 115px;
  text-align: center;
  .couponButton {
    line-height: 93px;
    background: url("../../assets/couponbutton.png") 0 0 no-repeat;
    background-size: 329px 99px;
    width: 329px;
    height: 99px;
    margin: 80px auto 0;
    font-size: 30px;
    font-weight: 500;
    color: rgba(222, 76, 65, 1);
  }
  .coupontitle {
    padding-top: 30px;
    font-size: 50px;
    font-family: BigruixianBoldkGBV1.0;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }
  .couponmoney {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .couponimg {
    display: flex;
    justify-content: space-around;
    width: 430px;
    height: 160px;
    background: url("../../assets/couponbgc.png") 0 0 no-repeat;
    background-size: 430px 160px;
    margin: 0 auto;
    .couponLeft {
      .couponallmoney {
        padding: 40px 0 10px 0;
      }
      .couponSymbol {
        font-size: 30px;
        color: rgba(244, 101, 72, 1);
      }
      .couponNumer {
        font-size: 48px;
        color: rgba(244, 101, 72, 1);
      }
      .couponCondition {
        font-size: 20px;
        font-weight: 300;
        color: rgba(153, 153, 153, 1);
      }
    }
    .couponRight {
      padding-top: 25px;
      .couponName {
        font-size: 30px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        padding-right: 40px;
        padding-bottom: 20px;
      }
      .couponTime {
        font-size: 12px;
        font-weight: 300;
        color: rgba(153, 153, 153, 1);
        padding-right: 10px;
      }
      .couponReason {
        font-size: 12px;
        font-weight: 300;
        color: rgba(153, 153, 153, 1);
        padding-right: 25px;
      }
    }
  }
  .couponClose {
    padding-top: 70px;
    opacity: 0.7;
  }
}
//公告
.leftIcon {
  position: absolute;
  left: 15px;
  top: 18px;
  font-size: 30px !important;
}
// .noticeBox{
//   padding-top: 60px;
// }
.leftContent {
  padding-left: 25px;
}
.navBar {
  position: relative;
  top: 0;
  left: 0;
  z-index: 999;
}
</style>