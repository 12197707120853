const state = {
    startLocation: null,
    transferLocation: [],
    endLocation: null
  };
  
  const mutations = {
      
  };
  
  const actions = {};
  
  export default {
    state,
    mutations,
    actions,
  };
  